import { useEffect, useState } from "react";
import PlayerWindow from "../../../video/playerComp";
import { useParams } from "react-router-dom"

const VideoTest = () => {

      const [inp1, setInp1] = useState('')
      const [plr, setPlr] = useState('')
      const { videoName } = useParams();

      useEffect(() => {
            console.log(videoName)
            async function getVideo() {

                  // let videoName =       
                  console.log('videoName: ' + videoName)
                  try {


                        let res = 
                        console.log(res)
                        let result = await res.json()
                        console.log(result)

                        // setInp1(link)
                        if (result.status === 'success') {
                              setPlr(<PlayerWindow url={result.link}></PlayerWindow>)

                        }
                  } catch (error) {

                  }
            }
            try {


                  getVideo()
            } catch (error) {
                  console.log(error)
            }
      }, [])

      const changeHandler = (e) => {
            e.preventDefault();
            const { value } = e.target
            console.log('value', value);
            setInp1(value)

      }

      async function play() {
            setPlr('')
            console.log('inp1 is: ' + inp1)
            setPlr(<PlayerWindow url={inp1}></PlayerWindow>)
      }
      return (
            <div>

                  <h1>this is video test component.</h1>

                  <input type="text" onChange={(e) => changeHandler(e)} />
                  <button onClick={play}>Play</button>
                  <br />
                  <br />
                  {plr}

            </div>
      )
}
export default VideoTest