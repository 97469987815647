import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const GetAllPrivateVideos = ({ selector, deletor }) => {
      const [videos, setVideos] = useState([]);
      const [error, setError] = useState('');

      useEffect(() => {
            const fetchVideos = async () => {
                  try {
                        const response = await fetch(apiUrl + 'privateVideo/getAllVideos', {
                              method: 'GET',
                              headers: {
                                    'x-auth-token': localStorage.getItem('token')
                              }
                        });
                        const data = await response.json();
                        console.log(data);
                        setVideos(data);
                  } catch (err) {
                        console.log(err);
                        setError('Error fetching videos');
                  }
            };
            fetchVideos();
      }, []);

      return (
            <div>
                  <h2>All Private Videos</h2>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <table className='admin-table'>
                        <thead>
                              <tr>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Directory Path</th>
                                    <th>Course</th>
                                    <th>Action</th>
                              </tr>
                        </thead>
                        <tbody>
                              {videos.map(video => (
                                    <tr key={video._id}>
                                          <td>{video.videoId}</td>
                                          <td>{video.videoTitle}</td>
                                          <td>{video.directoryPath}</td>
                                          <td>{video.course}</td>
                                          <td>
                                                <button onClick={() => { selector(video._id) }}>Update</button>
                                                &nbsp;|&nbsp;
                                                <button onClick={() => { deletor(video._id, video.videoTitle, video.videoId) }}>Delete</button>
                                          </td>
                                    </tr>
                              ))}
                        </tbody>
                  </table>
            </div>
      );
};

export default GetAllPrivateVideos;
