import { useState } from "react"
// import waitingIcon from "../../../../../asset/image/Icons/locked.svg"
import waitingIcon from "@as/image/Icons/LS14px.svg"
import waitingIcon2 from "@as/image/Icons/waiting2.svg"
import { apiUrl } from "@as/js/allStatics"
import { getUser, delayedProcessCheckRequest, checkDiscountCode, getPurchaseSettings } from "@as/js/userData"


function PayOverlay({ course, displayHandler, payment }) {

      const [finalPrice, setFinalPrice] = useState(payment.amount)
      const [referralCode, setReferralCode] = useState('')
      const [displayWaiting, setDisplayWaiting] = useState(false)
      const [isChecked, setIsChecked] = useState(false)
      const [codeApplyResult, setCodeApplyResult] = useState({})
      const [isReduced, setIsReduced] = useState(false)
      const [userWalletAddress, setUserWalletAddress] = useState('')
      const [disabled, setDisabled] = useState(false)
      const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false)

      function check() {
            // setConfirmBtnDisabled(!confirmBtnDisabled)
            // console.log(confirmBtnDisabled)
            //checkHandlerLog() 
            console.log('check function')
            setConfirmBtnDisabled(true)

      }



      // Handle form submission
      const checkReferalCodeInput = async (e) => {
            e.preventDefault();

            console.log('OnRefCode Validation')
            console.info(e.target.value)
            setReferralCode(
                  e.target.value,
            )
            // Basic validation
            let errors = {};
            // if (!formData.walletAddress) errors.walletAddress = 'Wallet address is required';
      }

      const confirmPay = async () => {
            //TODO: Show a wating after user clicked pay button
            setConfirmBtnDisabled(true)
            console.log('Chechbox: ' + isChecked)

            if (isChecked) {

                  var userAddress = ''
                  let tronweb = window.tronWeb;
                  if (!tronweb) {
                        alert('Please open your wallet and try again.')
                        setConfirmBtnDisabled(false)
                  }
                  try {

                        setTimeout(async () => {
                              try {
                                    await window.tronLink.request({ method: 'tron_requestAccounts' })

                                    // console.log(tronweb)
                                    // console.log(tronweb.isRevoked)
                                    if (!tronweb.defaultAddress.base58) {
                                          alert('Please open your wallet and try again.')
                                          setConfirmBtnDisabled(false)
                                    } else {
                                          try {

                                                console.log('tronweb: ')
                                                console.log(tronweb)
                                                console.log(tronweb.defaultAddress)

                                                userAddress = tronweb.defaultAddress.base58
                                                setUserWalletAddress(userAddress)
                                                var settingsRes = await getPurchaseSettings() 
                                                // var contractAddress = await getContractAddress() 
                                                console.log('contractAddress: ')
                                                let settingsVal = await settingsRes.json() 
                                                let settings = settingsVal.purchaseSettings
                                                console.log(settings)

                                                let contract = await window.tronLink.tronWeb.contract().at(settings.contractAddress);
                                                console.log('contract: ')
                                                console.log(contract)
                                                let usdtBalance = await contract.balanceOf(userAddress).call();
                                                console.log('User Balance: ' + usdtBalance)
                                                let amountToPay = finalPrice * (10 ** 6)

                                                console.log('amountToPay on course: ' + amountToPay)
                                                if (usdtBalance < amountToPay && settings.showInsufficientBalanceAlert) {
                                                      alert(`Insufficient ${payment.symbol} Balance on wallet.`)
                                                      setConfirmBtnDisabled(false)

                                                } else {
                                                      // Call for bill
                                                      let body = {
                                                            userId: await getUser(),
                                                            courseId: course.id,
                                                            chain: payment.chain,
                                                            userAddress: userAddress,
                                                            referralCode: referralCode

                                                      }
                                                      let fr = await fetch(apiUrl + 'purchase/getbill', {
                                                            method: 'post',
                                                            headers: { 'Content-Type': 'application/json' },
                                                            body: JSON.stringify(body)
                                                      }).catch((err) => {
                                                            console.log('Cathced error in bill request. error:')
                                                            console.log(err)
                                                      });
                                                      let result = await fr.json()
                                                      console.log(' call for purchase/getbill         ')
                                                      console.log(result)


                                                      if (result.status == 'error' && result.error == 'Repetitive purchase request' && settings.showRepetitiveAlert) {
                                                            setDisplayWaiting(false)
                                                            setDisabled(true)
                                                            return alert(result.msg)
                                                      }

                                                      let bill = result.data
                                                      console.log(bill)




                                                      console.log('bill.toPayPrice on bill: ' + bill.toPayPrice)

                                                      // Pay bill
                                                      var isOk = true;
                                                      //TODO: Fix Decimals
                                                      if (bill.toPayPrice != (amountToPay) && settings.showPriceDifferingConfirm) {
                                                            isOk = window.confirm('Pay amount is: ' + (bill.toPayPrice / 1000000) + ' ' + payment.symbol + '. Do you confirm it?')
                                                      }

                                                      if (isOk) {
                                                            console.log(' Go for pay - bill.toPayAddress:')
                                                            console.log(bill.toPayAddress)
                                                            let txResult = await contract.transfer(bill.toPayAddress, bill.toPayPrice).send();
                                                            //console.log(txResult)//ac9eb71df4cf7677091ae9f57589929d30b1f97efb98150d7889f4209dad8e7e
                                                            if (txResult.error) {
                                                                  alert(txResult.message)
                                                                  setConfirmBtnDisabled(false)
                                                            } else {
                                                                  let paidBody = {
                                                                        purchaseId: bill.purchaseId,
                                                                        txId: txResult
                                                                  }
                                                                  setTimeout(async () => {

                                                                        let fpr = await fetch(apiUrl + 'purchase/paidbill', {
                                                                              method: 'post',
                                                                              headers: { 'Content-Type': 'application/json' },
                                                                              body: JSON.stringify(paidBody)
                                                                        });
                                                                        let paidResult = await fpr.json()
                                                                        console.log(paidResult)
                                                                        if (paidResult.state == 'p') {
                                                                              delayedProcessCheckRequest()
                                                                              alert('Bill is under process. You can unlock the course in a few seconds.')
                                                                              displayHandler();
                                                                        }
                                                                        if (paidResult.state == 'c') {
                                                                              alert('Bill has been confirmed. You can unlock the course now.')
                                                                              displayHandler();
                                                                        }
                                                                        if (paidResult.state == 'f') {
                                                                              alert('Payment has been failed. Try later or contact support.')
                                                                        }
                                                                        if (paidResult.state == 'u') {
                                                                              alert('Bill status is unknown. Please try later or contact support.')
                                                                        }
                                                                        if (paidResult.state == 'm') {
                                                                              alert('Bill has a mismatched price. Please contact support.')
                                                                        }

                                                                        //setConfirmBtnDisabled(false)

                                                                  }, 2000);
                                                            }
                                                      } else {
                                                            console.log(' Cancel pay')
                                                            setConfirmBtnDisabled(false)
                                                      }
                                                }
                                          } catch (error) {
                                                console.log('#3 - Catch error:')
                                                console.log(error)
                                                let errMsg = 'Make sure your wallet is Open and Connected to Tron Grid.'
                                                if (error == 'Confirmation declined by user') {
                                                      errMsg = error
                                                }
                                                alert(error.message ? error.message : errMsg)
                                                // alert(error.message ? error.message : error)
                                                setConfirmBtnDisabled(false)
                                          }
                                    }
                              } catch (error) {
                                    console.log('Catch: tron_requestAccounts error:')
                                    console.log(error)

                              }
                        }, 1000);

                  } catch (error) {
                        alert(error.message ? error.message : error)
                        setConfirmBtnDisabled(false)
                  }

            } else {
                  alert('Please accept terms and conditons')
                  setConfirmBtnDisabled(false)
            }

      }


      const handleOutsideClick = e => {
            if (!this.node.contains(e.target)) displayHandler();
      };


      const checkHandler = () => {
            setIsChecked(!isChecked)
            console.log('Chechbox: ' + isChecked)
            setTimeout(() => {
                  console.log('Chechbox2: ' + isChecked)

            }, 1000);
      }
      const checkHandlerLog = () => {

            console.log('Chechbox: is:' + isChecked)
      }

      async function checkDiscount() {
            setDisplayWaiting(true)
            setDisabled(true)
            let discode = referralCode
            console.log('Enteed rferral code is: ' + discode)

            if (discode == '') {
                  //setDisabled(true)
                  setIsReduced(false)
                  setDisplayWaiting(false)
                  setDisabled(false)
                  setCodeApplyResult({
                        msg: 'Enter a Code.',
                        class: 'pol-action-discount-result-error'
                  })


                  return
            }


            // here should check if the discount code is valid and how much discount will be applied
            let codeCheckResultRaw = await checkDiscountCode(discode)
            let codeCheckResult = await codeCheckResultRaw.json()
            console.log('codeCheckResult ======================')
            console.log(codeCheckResult)
            if (codeCheckResult.status == 'active') {
                  // Scenario A: 50% dicount is applicable
                  setFinalPrice(Math.floor(payment.amount * 0.01 * Number(codeCheckResult.portion)))

                  setTimeout(() => {
                        setDisplayWaiting(false)
                        // setCodeApplyResult({ msg: 'Code has been expired.', ok: false,
                        //       class:'pol-action-discount-result-error' })
                        setCodeApplyResult({
                              msg: 'Discount has been applied.',
                              class: 'pol-action-discount-result-ok'
                        })

                        // setReferralCode()
                        setDisabled(true)

                        setIsReduced(true)
                  }, 2000);
            } else {

                  // Scenario B: No dicount is applicable
                  //setFinalPrice(Math.floor(payment.amount * 0.5))


                  setTimeout(() => {
                        setDisplayWaiting(false)
                        // setCodeApplyResult({ msg: 'Code has been expired.', ok: false,
                        //       class:'pol-action-discount-result-error' })
                        setCodeApplyResult({
                              msg: codeCheckResult.comment,
                              class: 'pol-action-discount-result-error'
                        })

                        // setReferralCode()
                        setDisabled(false)

                        setIsReduced(false)
                  }, 1000);
            }
      }


      return (
            <div className="pol-screen"
                  onClick={(e) => {
                        if (e.target.className === 'pol-screen' ||
                              e.target.className === 'pol-close-box' ||
                              e.target.className === 'pol-close-frame'
                        ) {
                              displayHandler();
                        }
                        // console.log(e);
                  }}>
                  <div className="pol-frame">
                        <div className="pol-close-frame">
                              <div className="pol-close-box">
                                    <div className="pol-close-btn" onClick={displayHandler}>Close</div>
                              </div>
                        </div>
                        <div className="pol-box" >
                              <div className="pol-box-body">
                                    <div className="pol-half">
                                          <div className="pol-info">

                                                <h1  >{course.courseName}</h1>
                                                <br />
                                                <p>
                                                      Your payment will be processed securely
                                                      using <span className="marked-lawn">{payment.symbol}</span> on the <span className="marked-lawn">{payment.chain}</span> blockchain.
                                                      The process may take a few seconds, depending on network traffic.
                                                      Please ensure your wallet is open and ready to authorize the transaction.
                                                </p>

                                                <p>
                                                      To access the course content, you’ll need to sign a message with your wallet.
                                                      Each signature is valid for 24 hours, and signing is <span className="marked-lawn">free</span>.
                                                </p>

                                          </div>
                                    </div>
                                    <div className="pol-half">
                                          <div className="pol-action">
                                                <div className="pol-action-price">
                                                      <h3> Price: {payment.amount} {payment.symbol}</h3>
                                                </div>
                                                <div className="pol-action-discount ">
                                                      Enter Discount Code (if any)
                                                      <div className="pol-action-discount-form">
                                                            <input type="text" onChange={checkReferalCodeInput}
                                                                  disabled={(disabled) ? "disabled" : ""} /> &nbsp;&nbsp;
                                                            <button className="apply-btn"
                                                                  onClick={checkDiscount}>Apply</button>
                                                      </div>
                                                      <div className="pol-action-discount-result">
                                                            <span className={codeApplyResult.class}>


                                                                  {codeApplyResult.msg}
                                                            </span>
                                                      </div>
                                                </div>


                                                <div className="pol-action-price">

                                                      <h3> Final Price:&nbsp;
                                                            {
                                                                  displayWaiting ?

                                                                        <span className="wating-box">
                                                                              <span>
                                                                                    <img className="waiting-logo" width={22} src={waitingIcon} alt="waitingIcon" />
                                                                              </span>
                                                                        </span>
                                                                        :
                                                                        isReduced ?
                                                                              <span> <span className="marked-lawn">{finalPrice}</span> {payment.symbol}</span>
                                                                              : <span> {finalPrice} {payment.symbol}</span>
                                                            }
                                                      </h3>
                                                </div>
                                                <div className="pol-action-term-frame">
                                                      <div className="pol-action-term-box">
                                                            <input type="checkbox"
                                                                  checked={isChecked}
                                                                  onChange={checkHandler}
                                                            /> I agree to the <a target="_blank" href="/terms_of_use">Terms of Use</a>
                                                      </div>
                                                </div>
                                                <div className="pol-action-cta-frame">
                                                      <div className="pol-action-cta-box">
                                                            <div className="pol-action-cta-box-row">
                                                                  <button className="free-access-btn"
                                                                        onClick={confirmPay} disabled={confirmBtnDisabled ? "disabled" : ''}
                                                                  >Confirm & Pay</button>
                                                                  {
                                                                        confirmBtnDisabled ? <span className="pol-action-cta-box-row-loading">
                                                                              &nbsp;&nbsp;<img className="waiting-logo-2" height={48} width={48} src={waitingIcon2} alt="waitingIcon" />
                                                                        </span> : <></>
                                                                  }

                                                            </div>
                                                            {/* <div className="pol-action-cta-box-row">
                                                                  <button className="apply-btn">Get support</button>
                                                            </div> */}
                                                      </div>
                                                </div>

                                          </div>
                                    </div>
                              </div>

                              <div className="pol-box-footer">
                                    <div className="pol-guide">
                                          <p><b>Need Help?</b> Learn how to apply discounts, choose payment methods, and confirm your transaction on the <a target="_blank" href="./How_to_Purchase">Purchase Guide</a>.

                                                If your problem persists, ask on <a target="_blank" href="./querywall">QueryWall</a>.</p>
                                    </div>
                              </div>

                              <div className="pol-close-box-2">
                                    <div className="pol-close-btn" onClick={displayHandler}>Close</div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}


export default PayOverlay
