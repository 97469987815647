




import { convertDuration, convertDurationString } from "@as/js/custom.js"
import { getCategoryForString } from "@as/js/categoryGuide"
import RatingStars from "./subComponent/rating-stars"
import CourseFullDescription from "./subComponent/courseFullDescription"
import DifficultyLevelBox from "./subComponent/difficulityLevelBox"
import CourseType from "./subComponent/courseType"
import PaymentOptions from "./subComponent/paymentOptions"
import VideoLinkBox from "./subComponent/videoLinkBox"
import BookmarkSign from "./subComponent/bookmarkSign"
import blub from "@as/image/template/E9.svg"
import { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom";
import { recordVisit, getListForPageOpen, setListForPageOpen } from "../../../../asset/js/site-cookies"
import Preview from "./subComponent/preview"
import PayOverlay from "./subComponent/payOverlay"
import { getMyCourses, saveSignature, delayedProcessCheckRequest } from '@as/js/userData'
import lockedIcon from "@as/image/Icons/locked.svg"
import unlockedIcon from "@as/image/Icons/unlocked.svg"
function SingleCourse({ courseData }) {
      console.log('catched course name: ' + courseData.courseName)
      console.info(courseData)

      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      useEffect(() => { 
            recordVisit(location.pathname)
            // getMyCourses()
      }, [myState])



      const [accessLinks, setAccessLinks] = useState(false)
      const [previewLinks, setPreviewLinks] = useState(false)
      const [showBox, setshowBox] = useState('display-none')
      const [showPay, setShowPay] = useState(false)
      const [inCourseUnlocked, setIsCourseUnlocked] = useState(false)
      const [selectedPetment, setSelectedPetment] = useState(null)
      const [paymentsClassName, setPaymentsClassName] = useState('payments-box')



      async function doUnlockTheCourse() {
            setIsCourseUnlocked(true)
            setLockIconImg(unlockedIcon)
      }



      // this is final one for lock icon to display on video list items
      const [lockIconImg, setLockIconImg] = useState(lockedIcon)


      async function sign() {
            try {
                  setTimeout(async () => {
                        return await unlockHandler()

                  }, 600);

            } catch (error) {
                  console.log(error)
            }
      }
      async function unlockHandler() {
            // Handle Unlock After sign (doesnt show to the user that unluck is done)
            try {
                  delayedProcessCheckRequest()

            } catch (error) {

            }
            var userAddress = ''
            let tronweb = window.tronWeb;
            if (!tronweb) {
                  alert('Please open your wallet and try again.')
                  //setConfirmBtnDisabled(false)
            } else {


                  await window.tronLink.request({ method: 'tron_requestAccounts' })
                  // Seem the 'tron_requestAccounts' is going to be descriptiated.
                  //https://github.com/tronprotocol/tips/issues/463

                  // This wont work:
                  // try {
                  //       await tron.request({ method: 'eth_requestAccounts' });
                  // } catch (e) { }



                  // console.log(tronweb)
                  // console.log(tronweb.isRevoked)
                  if (!tronweb.defaultAddress.base58) {
                        alert('Please open your wallet and try again.')
                  } else {
                        try {
                              console.log(tronweb.defaultAddress.base58)
                              userAddress = tronweb.defaultAddress.base58
                              let gres = await getMyCourses(userAddress, courseData.courseId)
                              console.log('On Course page - getMyCourses - gres:')
                              console.log(gres)
                              if (gres.status == 'sign') {
                                    console.log('user must sign the message')
                                    var signature = null;
                                    try {
                                          signature = await tronweb.trx.signMessageV2(gres.message) || '';
                                          console.log(signature)
                                          // TODO: When this saveSignature failes from server, the app crashes.
                                          // Do handle this error to avoid crash.
                                          // desktop/crasherror.png
                                          let data = await saveSignature(signature, gres.time, userAddress)
                                          console.log('data after sign:')
                                          console.log(data)
                                          if (data.accessToken.length > 8) {
                                                //setIsCourseUnlocked(true)
                                                return await doUnlockTheCourse()
                                          }

                                    } catch (error) {
                                          console.log(signature)
                                          console.log('u alert 1')

                                          alert(error.message ? error.message : error)
                                    }
                              }
                              else {
                                    if (gres.status == 'error') {
                                          alert(gres.msg)

                                    } else {
                                          console.log('user has access to data')
                                          //setIsCourseUnlocked(true)
                                          return await doUnlockTheCourse()
                                    }
                              }

                        } catch (error) {
                              console.log('u alert 2')
                              console.log(error)
                              console.log(error.message)
                              alert(error.message ? error.message : error)
                              //setConfirmBtnDisabled(false)
                        }
                  }
            }
      }

      function handleShowPayOverlay(p, courseId) {
            setSelectedPetment(p)
            setShowPay(!showPay)

      }




      async function check() {
            console.log('At Single course')
            getMyCourses()
      }



      // The two function do pretty same.
      // open and close the list at nd for videos accessLinks.
      // changePreviewLink and changeAccesssLink
      function changePreviewLink() {
            console.log('single course  - changePreviewLink')
            setPreviewLinks(!previewLinks)
            if (showBox === 'display-none') {
                  setshowBox('display-block')
                  setListForPageOpen(location.pathname, true)

            } else {
                  setshowBox('display-none')
                  setListForPageOpen(location.pathname, false)

            }
      }

      function changeAccesssLink() {
            console.log('single course  - changeAccesssLink')
            setAccessLinks(!accessLinks)
            if (showBox === 'display-none') {
                  setshowBox('display-block')
                  setListForPageOpen(location.pathname, true)
            } else {
                  setshowBox('display-none')
                  setListForPageOpen(location.pathname, false)

            }
      }

      useEffect(() => {
            // to check if video list was open berofe reload
            // similar to bookmarks. but storing if the list is open
            getListForPageOpen(location.pathname).then((check) => {
                  if (check) {
                        setshowBox('display-block')
                        console.log('called     setshowBox(\'display - block\')')
                  }
            })
      }, [myState])



      return (
            <>
                  {showPay ?

                        <PayOverlay

                              payment={selectedPetment}
                              displayHandler={handleShowPayOverlay} course={courseData} className="p-full-screen" />

                        : null}

                  <div className="course-body">

                        <img className="head-img" src={courseData.image} alt={"Course image - for " + courseData.courseName} />
                        <h1 className="head-text">{courseData.courseName}</h1>

                        <h2 className="Shortdescription">
                              {courseData.shortDescription}
                        </h2>

                        <div>
                              <button onClick={() => {
                                    if (showBox === 'display-none') {
                                          changeAccesssLink()
                                    }

                                    setTimeout(() => {
                                          document.getElementById('video-box')?.scrollIntoView({ behavior: 'smooth' })

                                    }, 100);
                              }} className="see-videos-btn">
                                    See Videos List
                              </button>
                        </div>
                        <div className="longdescription">
                              <CourseFullDescription text={courseData.courseBody} />
                        </div>


                        <div className="course-info-body">

                              <div className="course-bulb">
                                    <div className="bulb-b">
                                          <img width="400" height="400" src={blub} alt="background bulb" />
                                    </div>
                                    <div className="bulb-s">
                                          <img width="200" height="200" src={blub} alt="background bulb" />
                                    </div>
                              </div>


                              <div className="course-info">
                                    <div className="course-detail-head" onClick={check}>
                                          Course Details:
                                    </div>



                                    <table className="content-table">
                                          <tbody>
                                                <tr>
                                                      <td className="td-title">Title:</td>
                                                      <td className="td-description">
                                                            <div className="first-td-desc">
                                                                  <span className="f1">{courseData.shortTitle}</span>
                                                                  <span className="f2">

                                                                        <BookmarkSign courseId={courseData.id} />

                                                                  </span>
                                                            </div>


                                                      </td>
                                                </tr>


                                                <tr>
                                                      <td className="td-title">Category:</td>
                                                      <td className="td-description"> {getCategoryForString(courseData.category)}
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td className="td-title">Technologies Used:</td>

                                                      <td className="td-description  ">
                                                            <div className="tach-frame">
                                                                  <div className="techs-bar" title="Technolgies Used in the course">

                                                                        {courseData.technologiesUsed.map((t, i) => (
                                                                              <div className="techs" key={i}>{t}</div>
                                                                        ))}

                                                                  </div></div>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td className="td-title">Course Duration:</td>
                                                      <td className="td-description"  > <span title={convertDurationString(courseData.duration)}> {convertDuration(courseData.duration)}  </span>
                                                            &nbsp;&nbsp; - &nbsp;&nbsp; {courseData.numberOfReleasedVideos} {courseData.numberOfReleasedVideos > 1 ? 'Sessions' : 'Session'}</td>
                                                </tr>
                                                <tr>
                                                      <td className="td-title">Rating:</td>
                                                      <td className="td-description" title="">
                                                            <RatingStars number={courseData.rating ? courseData.rating : 5} />
                                                      </td>
                                                </tr>

                                                <tr className="key-features">
                                                      <td className="td-title">Key Takeaways:</td>
                                                      <td className="td-description">
                                                            <ul>
                                                                  {courseData.keyTakeaways.map((t, i) => (
                                                                        <li className="features-li" key={i}>
                                                                              <b>{t.head}:</b>
                                                                              &nbsp;
                                                                              {t.body} </li>
                                                                  ))}

                                                            </ul>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td className="td-title">Difficulty level:</td>
                                                      <td className="td-description">
                                                            <DifficultyLevelBox dif={courseData.difficultyLevel} />
                                                      </td>
                                                </tr>
                                                {courseData.prerequisites.length > 1 ?
                                                      <tr>
                                                            <td className="td-title">Prerequisites:</td>
                                                            <td className="td-description"  >
                                                                  {courseData.prerequisites}
                                                            </td>
                                                      </tr>
                                                      : ''}
                                                <tr id="type-section">
                                                      <td className="td-title">Type:</td>
                                                      <td className="td-description"><CourseType courseTypeIndex={courseData.courseType} /></td>
                                                </tr>
                                                <tr>
                                                      <td className="td-title">Teacher:</td>
                                                      <td className="td-description">     <span> {courseData.author.name} </span>
                                                            {/* <td className="td-description">       - <a href="#">Teacher Profile</a> */}
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td className="td-title" id="payment-options-section">Payment Options:</td>
                                                      <td className="td-description">
                                                            <div className={paymentsClassName}>
                                                                  <PaymentOptions course={courseData}
                                                                        accessHandler={changeAccesssLink}
                                                                        displayHandler={handleShowPayOverlay}
                                                                        paymentSelector={setSelectedPetment} />
                                                            </div></td>
                                                </tr>
                                                {courseData.isAccessibleForFree ? '' : <tr>
                                                      <td className="td-title" >Preview:</td>
                                                      <td className="td-description">
                                                            <div className="payments-box">
                                                                  <Preview courseAP={courseData.acceptedPayments} isFree={courseData.isAccessibleForFree} accessHandler={changePreviewLink} />
                                                            </div></td>
                                                </tr>

                                                }

                                          </tbody>
                                    </table>

                              </div>
                              {courseData.isAccessibleForFree ?
                                    <div className={showBox} id="video-box">
                                          <VideoLinkBox course={courseData} ids={courseData.videos} assetIds={courseData.sideAssets} courseTitle={courseData.title} />
                                    </div>
                                    : <div className={showBox} id="video-box">
                                          <VideoLinkBox course={courseData} ids={courseData.videos} assetIds={courseData.sideAssets}
                                                courseTitle={courseData.title} blinker={setPaymentsClassName} isUnlocked={inCourseUnlocked}
                                                doUnlockHandler={sign} lockDisplayIcon={lockIconImg}
                                          />
                                    </div>

                              }

                        </div>
                  </div>
            </>
      )
}

export default SingleCourse 
