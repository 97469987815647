import MainLayout from "../template/MainLayout"
import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
import { fetchArticles } from "@as/js/data"
import ArticlesListItem from "./ArticlesListItem";
import { getAllCategories } from "@as/js/categoryGuide"
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { Helmet } from "react-helmet";

const ArticleList = () => {

      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])

      const { rootCategory, mainCategory, subCategory, title } = useParams();
      const navigate = useNavigate();

      const [articles, setArticles] = useState([])

      useEffect(() => {
            fetchArticles().then(async (allArticles) => {

                  if (rootCategory) {

                        var categs = await getAllCategories()
                        var rootCatInd = categs.filter(c => c.title.toLowerCase() == rootCategory.toLowerCase() || c.title == rootCategory)
                        console.info(rootCatInd)
                        if (rootCatInd.length == 0) {
                              setArticles(allArticles)
                        } else {

                              var rootArticles = allArticles.filter(c => c.category[0] == rootCatInd[0].num)


                              if (mainCategory) {

                                    var mainIndexCat = categs[rootCatInd[0].num].mainCategories.filter(c => c.title.toLowerCase() == mainCategory.toLowerCase() || c.title == mainCategory)
                                    if (mainIndexCat.length == 0) {
                                          setArticles(rootArticles)
                                    } else {
                                          var mainIndex = mainIndexCat[0].num
                                          console.log('on main article ind: ' + mainIndex)
                                          var mainArticles = rootArticles.filter(c => c.category[1] == mainIndex)
                                          if (mainArticles.length == 0) {
                                                setArticles(rootArticles)
                                          } else {


                                                if (subCategory) {

                                                      var subIndexCats = categs[rootCatInd[0].num].mainCategories[mainIndex].subCategories.filter(c => c.title.toLowerCase() == subCategory.toLowerCase() || c.title == subCategory)
                                                      if (subIndexCats.length == 0) {
                                                            setArticles(mainArticles)
                                                      } else {

                                                            var subIndex = subIndexCats[0].num
                                                            console.log('on SUB articles ind: ' + subIndex)
                                                            var subArticles = mainArticles.filter(c => c.category[2] == subIndex)

                                                            if (subArticles.length == 0) {
                                                                  navigate("/");
                                                                  return
                                                            }

                                                            if (title) {

                                                                  console.log('on Article title  title: ' + title)
                                                                  var titleArticles = subArticles.filter(c => c.title.toLocaleLowerCase() == title.toLocaleLowerCase() || c.id == title)
                                                                  if (titleArticles.length == 0) {
                                                                        navigate("/");
                                                                        return
                                                                  }
                                                                  setArticles(titleArticles)
                                                            } else {
                                                                  setArticles(subArticles)
                                                            }
                                                      }

                                                } else {
                                                      setArticles(mainArticles)

                                                }
                                          }
                                    }

                              } else {
                                    setArticles(rootArticles)
                              }
                        }

                  } else {
                        setArticles(allArticles)
                  }
            })
      }, [])

      

      var content = {
            url: 'https://melarix.com/articles',
            title: 'Articles on Cryptocurrency and Blockchain Technology - Expert Insights',
            description: 'Explore detailed, informative articles covering cryptocurrency, blockchain technology, and advanced concepts in the field.',
      type: 'website',
            image: 'https://melarix.com/melarix-mascot.png'
      }
      return (
            <MainLayout>
                  <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />

                        <link rel="canonical" href={content.url.toString()} />

                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>
                  <div className="article-body">
                        <h1>Articles List:</h1><br />
                        {articles.map((article,i) => (
                              <ArticlesListItem article={article} key={i} />
                        ))}
                  </div>
            </MainLayout>
      );
};

export default ArticleList