import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'

const CreatePaidCourse = () => {
      const [courseTitle, setCourseTitle] = useState('');
      const [courseId, setCourseId] = useState('');
      const [coursePrivateVideoIds, setCoursePrivateVideoIds] = useState([]);
      const [coursePrivateAssetIds, setCoursePrivateAssetIds] = useState([]);
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  const newCourse = {
                        courseId,
                        courseTitle,
                        coursePrivateVideoIds,
                        coursePrivateAssetIds
                  };
                  const response = await fetch(apiUrl + 'paidCourse/createPaidCourse', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(newCourse)
                  });
                  console.log('Create paid course > handleSubmit > response:')
                  console.log(await response.json())
                  setSuccess('Course created successfully');
                  setError('');
            } catch (err) {
                  console.log(err)
                  setError(err.msg || 'Error creating course');
                  setSuccess('');
            }
      };

      const handleVideoIdsChange = (e) => {
            setCoursePrivateVideoIds(e.target.value.split(','));
      };

      const handleAssetIdsChange = (e) => {
            setCoursePrivateAssetIds(e.target.value.split(','));
      };

      return (
            <div>
                  <h2>Create Paid Course</h2>
                  <form onSubmit={handleSubmit} className="admin-form">
                        <div>
                              <label>Course ID</label>
                              <input
                                    type="text"
                                    value={courseId}
                                    onChange={(e) => setCourseId(e.target.value)}
                                    required
                              />
                        </div>
                        <div>
                              <label>Course Title</label>
                              <input
                                    type="text"
                                    value={courseTitle}
                                    onChange={(e) => setCourseTitle(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Private Video IDs (comma-separated)</label>
                              <input
                                    type="text"
                                    value={coursePrivateVideoIds}
                                    onChange={handleVideoIdsChange}
                              />
                        </div>
                        <div>
                              <label>Private Asset IDs (comma-separated)</label>
                              <input
                                    type="text"
                                    value={coursePrivateAssetIds}
                                    onChange={handleAssetIdsChange}
                              />
                        </div>
                        <button type="submit">Create Course</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
            </div>
      );
};

export default CreatePaidCourse;
