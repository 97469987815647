import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import MainLayout from "../../template/MainLayout"

const AgentResendEmail = () => {
      // Define form data and error state
      const [formData, setFormData] = useState({
            email: '',
            password: ''
      });

      const [errors, setErrors] = useState([]);
      const [successMessage, setSuccessMessage] = useState('');

      const { email, password } = formData;

      // Handle input changes
      const onChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      // Handle form submission
      const onSubmit = async (e) => {
            e.preventDefault();

            try {
                  // Send POST request using fetch
                  const response = await fetch(apiUrl + 'agent/resend-verification', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                  });

                  const resData = await response.json();

                  if (response.ok) {
                        // Successful response, save token or handle as needed
                        // localStorage.setItem('token', resData.token); // Example of storing token
                        setSuccessMessage(
                              "Verification link has been sent to your email.");
                        console.log(resData)

                        setErrors([]); // Clear errors on success
                  } else {
                        // If there are validation errors
                        setErrors(resData.errors || [{ msg: 'Server error - ' + (resData.msg || "") }]);
                        console.log(resData)
                  }

            } catch (error) {
                  // Handle general errors
                  console.log(error)

                  setErrors([{ msg: 'Server error, please try again later.' }]);
            }
      };

      return (
            <MainLayout>
                  <div className="form-container">
                        <h2> Agent - Resend Email</h2>

                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {successMessage && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>{successMessage}</p>
                              </div>
                        )}

                        <form onSubmit={onSubmit}>
                              <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                          type="email"
                                          name="email"
                                          value={email}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                    />
                              </div>
 

                              <button type="submit" className="reg-btn">Send</button>
                              <div className='already'>
                                    Back to <a href="./agent/login">login</a>
                              </div>
                              <br /><br />
                        </form>
                  </div>
                  <div className="ps-note">
                        <p>If your email is already verified, please visit <a href="/agent/ForgotPassword"> ForgotPassword</a> for a new password reset link </p>
                  </div>
            </MainLayout>
      );
};

export default AgentResendEmail;
