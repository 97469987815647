import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const UpdatePrivateVideo = ({ match }) => {
      const [videoTitle, setVideoTitle] = useState('');
      const [videoId, setVideoId] = useState('');
      const [directoryPath, setDirectoryPath] = useState('');
      const [course, setCourse] = useState('');
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      useEffect(() => {
            const fetchVideo = async () => {
                  try {
                        const response = await fetch(apiUrl + `privateVideo/getVideo/${match.params.id}`, {
                              method: 'GET',
                              headers: { 'x-auth-token': localStorage.getItem('token') }
                        });
                        const data = await response.json();
                        setVideoTitle(data.videoTitle);
                        setVideoId(data.videoId);
                        setDirectoryPath(data.directoryPath);
                        setCourse(data.course);
                  } catch (err) {
                        console.log(err);
                        setError('Error fetching video');
                  }
            };
            fetchVideo();
      }, [match.params.id]);

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  const updatedVideo = { videoTitle, videoId, directoryPath, course };
                  const response = await fetch(apiUrl + `privateVideo/updateVideo/${match.params.id}`, {
                        method: 'PUT',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(updatedVideo)
                  });
                  const data = await response.json();
                  console.log(data);
                  setSuccess('Video updated successfully');
                  setError('');
            } catch (err) {
                  console.log(err);
                  setError('Error updating video');
                  setSuccess('');
            }
      };

      return (
            <div>
                  <h2>Update Private Video</h2>
                  <form onSubmit={handleSubmit} className='admin-form'>
                        <div>
                              <label>Video ID</label>
                              <input
                                    type="text"
                                    value={videoId}
                                    onChange={(e) => setVideoId(e.target.value)}
                                    required
                              />
                        </div>
                        <div>
                              <label>Video Title</label>
                              <input
                                    type="text"
                                    value={videoTitle}
                                    onChange={(e) => setVideoTitle(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Directory Path</label>
                              <input
                                    type="text"
                                    value={directoryPath}
                                    onChange={(e) => setDirectoryPath(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Course</label>
                              <input
                                    type="text"
                                    value={course}
                                    onChange={(e) => setCourse(e.target.value)}
                              />
                        </div>
                        <button type="submit">Update Video</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
            </div>
      );
};

export default UpdatePrivateVideo;
