function PaymentOptions({ course, accessHandler, displayHandler, paymentSelector }) {
      if (course.isAccessibleForFree) {
            return (
                  <>
                        <div className="padding-bottom-0">
                              <p>Free Access for all Students. 😉👍</p>
                        </div>
                        <div className="get-course-btn">
                              <button className="free-access-btn" onClick={() => accessHandler()}>Get the course</button>
                        </div>
                  </>

            )
      } else {
            return (

                  <>

                        {course.acceptedPayments.map((p, i) => (

                              <div
                                    onClick={() => displayHandler(p, course.id)}
                                    key={i} className="payment-option" title={'Pay with ' + p.symbol + ' on ' + p.chain + ' blockchain.'}>
                                    <img src={require('@as/image/coin/' + p.symbol + '.png')} alt="Coin" className="" />
                                    <span>{p.amount}</span>
                                    <span>{p.symbol}</span>
                              </div>
                        ))}
                        <div className="purchase-guide-link-box">
                              <a target="_blank" href="./How_to_Purchase">See Purchase Guide</a>
                        </div>
                  </>


            )


      }

}
 


export default PaymentOptions