import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import MainLayout from '../../template/MainLayout';
import { useNavigate } from 'react-router-dom';
import SendResultDisplay from './subComponents/sendResultDisplay'
import AdminPanel from './subComponents/adminPanel';
const AgentDashboard = () => {
      const [agentData, setAgentData] = useState({
            walletAddress: '',
            nickName: '',
            bio: '',
            profileImage: '',
            bannerImage: '',
            socialLinks: [],
            email: '',
            telegram: '',
            twoFactorAuth: false,
            referralCode: '',
            saleHistory: []
      });

      const [editableBio, setEditableBio] = useState('');
      const [userEmail, setUserEmail] = useState('');
      const [passwordChangeRequest, setPasswordChangeRequestState] = useState('');
      const [newSocialLink, setNewSocialLink] = useState({ name: '', link: '' });
      const [errors, setErrors] = useState([]);
      const [successMessage, setSuccessMessage] = useState('');
      const [addLinkState, setAddLinkState] = useState({});
      const [removeLinkState, setRemoveLinkState] = useState('');
      const [updateBioState, setUpdateBioState] = useState('');
      const [editableReferralCode, setEditableReferralCode] = useState('');
      const [updateReferralCodeState, setUpdateReferralCodeState] = useState('');
      const [editableTelegram, setEditableTelegram] = useState('');
      const [updateTelegramState, setUpdateTelegramState] = useState('');
      const [adminPanel, setAdminPanel] = useState(undefined);

      let navigate = useNavigate();




      // Fetch agent data from the API on load
      useEffect(() => {
            let token = localStorage.getItem('token');
            console.log(token)
            if (!token) {
                  navigate('/agent/login')
            }

            // Replace with actual API call to get the agent data
            const fetchAgentData = async () => {
                  // Example fetch call to get agent data
                  const res = await fetch(apiUrl + 'agent/me', {
                        method: 'GET',
                        headers: { 'x-auth-token': token },
                  });
                  console.log(res)
                  console.log(res)

                  const data = await res.json();
                  console.log(data)
                  if (data.msg == 'agent not found' || data.msg == 'Token is not valid') {
                        navigate('/agent/login')
                  }
                  setAgentData(data);
                  setEditableBio(data.bio); // Initialize editable bio
                  setEditableReferralCode(data.referralCode)
                  setEditableTelegram(data.telegram)
                  setUserEmail(data.email)
            };

            fetchAgentData();

            async function isAdmin() {
                  const res = await fetch(apiUrl + 'admin/isadmin', {
                        method: 'GET',
                        headers: { 'x-auth-token': token },
                  });
                  console.log(res)
                  let res2 = await res.json()
                  console.log(res2)
                  if (res2.msg != "Access denied") {
                        setAdminPanel(<AdminPanel></AdminPanel>)

                  }
            }
            isAdmin()
      }, []);

      // Handle updating bio
      const updateBio = async () => {
            try {
                  const res = await fetch(apiUrl + 'agent/me/updatebio', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')

                        },
                        body: JSON.stringify({ bio: editableBio })
                  });
                  const data = await res.json();
                  if (res.ok) {
                        setAgentData({ ...agentData, bio: editableBio });
                        setSuccessMessage('Bio updated successfully');
                        setUpdateBioState({ msg: 'Bio updated successfully', isSuccess: true });
                  } else {
                        setUpdateBioState({ msg: 'Error in update Bio', isSuccess: false });
                        setErrors(data.errors || [{ msg: 'Error updating bio' }]);
                        console.log(data)
                        if (data.error == 'too long') {
                              setUpdateBioState({ msg: 'Error in update Bio - ' + data.msg, isSuccess: false });

                        }
                  }
            } catch (error) {
                  setUpdateBioState({ msg: 'Error in update Bio' + (error.msg || ""), isSuccess: false });

                  setErrors([{ msg: 'Server error while updating bio' }]);
            }
      };

      // Handle social link addition
      const addSocialLink = async () => {
            const updatedSocialLinks = [...agentData.socialLinks, newSocialLink];
            console.log(updatedSocialLinks[updatedSocialLinks.length - 1])
            try {
                  const res = await fetch(apiUrl + 'agent/me/addSocialLink', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify({ newSocialLink: updatedSocialLinks[updatedSocialLinks.length - 1] })
                  });
                  const data = await res.json();

                  if (res.ok) {
                        setAgentData({ ...agentData, socialLinks: updatedSocialLinks });
                        setNewSocialLink({ name: '', link: '' });
                        setSuccessMessage('Social link added successfully');
                        setAddLinkState({ msg: 'Social link added successfully', isSuccess: true });
                  } else {
                        console.log(data)
                        setAddLinkState({ msg: 'Error in add Social link' + (data.msg || ""), isSuccess: false });
                  }
            } catch (error) {
                  console.log(error)

                  setErrors([{ msg: 'Error adding social link' }]);
                  setAddLinkState({ msg: 'Error in add Social link' + (" - " + error.msg || error.message || ""), isSuccess: false });

                  console.log(error)
            }
      };

      // Handle social link addition
      const removeSocialLink = async (id, key) => {
            console.log(' To remove social link: ' + id)

            const updatedSocialLinks = [...agentData.socialLinks, newSocialLink];
            try {
                  const res = await fetch(apiUrl + 'agent/me/removeSocialLink/' + id, {
                        method: 'GET',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        // body: JSON.stringify({ socialLinks: updatedSocialLinks })
                  });
                  if (res.ok) {
                        // setAgentData({ ...agentData, socialLinks: updatedSocialLinks });
                        // setNewSocialLink({ platform: '', link: '' });
                        setSuccessMessage('Social link removed successfully');
                        setRemoveLinkState({ msg: 'Social link removed successfully', isSuccess: true });
                        document.getElementById('id-' + key).remove();
                  } else {
                        setRemoveLinkState({ msg: 'Error in remove Social link.', isSuccess: false });

                  }
            } catch (error) {
                  setRemoveLinkState({ msg: 'Error in remove Social link.' + (error.msg || "-"), isSuccess: false });

                  setErrors([{ msg: 'Error removing social link' }]);
            }
      };

      // Handle request for updating referral code
      const updateReferralCode = async () => {
            try {
                  const res = await fetch(apiUrl + 'agent/me/request-referral-code', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')

                        },
                        body: JSON.stringify({ requestedReferralCode: editableReferralCode })
                  });
                  const data = await res.json();
                  if (res.ok) {
                        // setAgentData({ ...agentData, bio: editableBio });
                        setSuccessMessage('Referral Code updated request has been send.');
                        setUpdateReferralCodeState({ msg: 'Referral Code updated request has been send.', isSuccess: true });
                        setErrors([])
                  } else {
                        setUpdateReferralCodeState({ msg: 'Error in update Referral Code ' + (" - " + data.msg || ""), isSuccess: false });
                        setErrors(data.errors || [{ msg: 'Error updating Referral Code' }]);
                        console.log(data)

                  }
            } catch (error) {
                  setUpdateReferralCodeState({ msg: 'Error in update Referral Code' + (error.msg || ""), isSuccess: false });
                  console.log(error)

                  setErrors([{ msg: 'Server error while updating Referral Code' }]);
            }
      };

      // Handle request for updating telegram
      const updateTelegram = async () => {
            try {
                  const res = await fetch(apiUrl + 'agent/me/update-telegram', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')

                        },
                        body: JSON.stringify({ telegram_handle: editableTelegram })
                  });
                  const data = await res.json();
                  if (res.ok) {
                        // setAgentData({ ...agentData, bio: editableBio });
                        setSuccessMessage('Telegram has been updateded successfully.');
                        setUpdateTelegramState({ msg: 'Telegram has been updateded successfully.', isSuccess: true });
                        setErrors([])
                  } else {
                        setUpdateTelegramState({ msg: 'Error in update telegram ' + (" - " + data.msg || ""), isSuccess: false });
                        setErrors(data.errors || [{ msg: 'Error updating telegram' }]);
                        console.log(data)

                  }
            } catch (error) {
                  setUpdateTelegramState({ msg: 'Error updating telegram' + (error.msg || ""), isSuccess: false });
                  console.log(error)

                  setErrors([{ msg: 'Server error while updating telegram' }]);
            }
      };

      // Handle request for updating telegram
      const sendPasswordReset = async () => {
            try {
                  const res = await fetch(apiUrl + 'agent/forgot-password', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify({ email: userEmail })
                  });
                  const data = await res.json();
                  if (res.ok) {
                        // setAgentData({ ...agentData, bio: editableBio });
                        setSuccessMessage('Password reset request has been send successfully.');
                        setPasswordChangeRequestState({ msg: 'Password reset request has been send successfully.', isSuccess: true });
                        setErrors([])
                  } else {
                        setPasswordChangeRequestState({ msg: 'Error in Password reset request ' + (" - " + data.msg || ""), isSuccess: false });
                        setErrors(data.errors || [{ msg: 'Error in Password reset request' }]);
                        console.log(data)

                  }
            } catch (error) {
                  setPasswordChangeRequestState({ msg: 'Error in Password reset request' + (error.msg || ""), isSuccess: false });
                  console.log(error)

                  setErrors([{ msg: 'Server error while Password reset requesting' }]);
            }
      };


      //  This function suppose to handle upload profile pictur for agent
      // maybe released later
      // corresponding input and button have been commented on the return
      /*
      const handleProfileImageUpload = async (event) => {
            const file = event.target.files[0];

            // File size limit: 2MB (2 * 1024 * 1024 bytes)
            const maxSize = 2 * 1024 * 1024;

            // Allowed file types
            const allowedFileTypes = ['image/jpeg', 'image/png'];

            // Check if file exists
            if (!file) {
                  return;
            }

            // Check file type
            if (!allowedFileTypes.includes(file.type)) {
                  alert('Only JPG and PNG formats are allowed.');
                  return;
            }

            // Check file size
            if (file.size > maxSize) {
                  alert('File size should not exceed 2MB.');
                  return;
            }

            // If file passes checks, proceed with the upload
            const formData = new FormData();
            formData.append('profileImage', file);

            try {
                  const response = await fetch('/agent/me/profileImage', {
                        method: 'POST',
                        headers: {
                              // 'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: formData,
                  });

                  if (response.ok) {
                        // Assuming the API returns the updated profile image URL
                        const updatedData = await response.json();
                        setAgentData({
                              ...agentData,
                              profileImage: updatedData.profileImage,
                        });
                  } else {
                        console.error('Failed to upload image');
                  }
            } catch (error) {
                  console.error('Error uploading image:', error);
            }
      };
      */



      return (
            <MainLayout>
                  <div className=" ">
                        <div className="info-display">
                              <h1>Agent Dashboard</h1>
                        </div>
                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {successMessage && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>{successMessage}</p>
                              </div>
                        )}


                        {/* Banner Image */}
                        <div className="banner-section" style={{ textAlign: 'center', position: 'relative' }}>
                              {agentData.bannerImage && (
                                    <img
                                          src={agentData.bannerImage}
                                          alt="Banner"
                                          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                                    />
                              )}

                              {/* Profile Image */}
                              {/* <div style={{ position: 'relative',
                                    //  display: 'inline-block' 
                                     }}> */}
                              {agentData.profileImage && (
                                    <img
                                          src={agentData.profileImage}
                                          alt="Profile"
                                          style={{
                                                width: '150px',
                                                height: '150px',
                                                borderRadius: '50%',
                                                position: 'absolute',
                                                bottom: '-75px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                border: '5px solid white'
                                          }}
                                    />
                              )}

                              {/* Upload Button */}
                              {/* <div
                                          style={{
                                                position: 'absolute',
                                                bottom: '-10px',
                                                left: '50%',
                                                transform: 'translate(-50%, 0)',
                                                zIndex: 2,
                                                cursor: 'pointer',
                                          }}
                                    >
                                          <button
                                                style={{
                                                      backgroundColor: '#007bff',
                                                      color: 'white',
                                                      borderRadius: '50%',
                                                      width: '40px',
                                                      height: '40px',
                                                      fontSize: '24px',
                                                      border: 'none',
                                                      cursor: 'pointer',
                                                }}
                                                onClick={() => document.getElementById('fileInput').click()}
                                          >
                                                +
                                          </button>
                                    </div> */}

                              {/* Hidden File Input */}
                              {/* <input
                                          id="fileInput"
                                          type="file"
                                          accept="image/*"
                                          style={{ display: 'none' }}
                                          onChange={handleProfileImageUpload}
                                    />
                              </div> */}
                        </div>


                        {/* Nickname */}
                        <div style={{ textAlign: 'center', marginTop: '100px' }}>
                              <h1 style={{ fontSize: '48px', fontWeight: 'bold' }}>{agentData.nickName}</h1>
                        </div>

                        {/* Bio */}
                        {agentData.bio && (
                              <div className="info-display">
                                    <div className="bio-section " style={{ textAlign: 'center', marginTop: '20px' }}>
                                          <p>{agentData.bio}</p>
                                    </div>
                                    {/* <p>{agentData.bio}</p> */}
                              </div>
                        )}

                        {/* Social Links */}
                        <div className="social-links-display" style={{ textAlign: 'center', marginTop: '30px' }}>
                              <h3>Check out my content on:</h3>
                              {
                                    console.log("agentData.socialLinks.length: " + agentData.socialLinks.length)
                              }
                              {agentData.socialLinks.length > 0 && (
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                          {agentData.socialLinks.map((link, index) => (
                                                <li key={index} style={{ margin: '10px 0' }}>
                                                      <a href={link.link} target="_blank" rel="noopener noreferrer">
                                                            {link.name}
                                                      </a>
                                                </li>
                                          ))}
                                    </ul>
                              )}

                              {/* {agentData.telegram && (
                                    <p>
                                          <strong>Telegram:</strong> <a href={`https://t.me/${agentData.telegram}`} target="_blank" rel="noopener noreferrer">{agentData.telegram}</a>
                                    </p>
                              )} */}
                        </div>





                        <div className="private-agent-profile">


                              <div>
                                    <br />
                                    <hr />  <p>Public info: </p>
                                    <br />
                              </div>
                              {/* Display Agent Info */}
                              <div className="agent-info">


                                    <div className='agent-info-row'>
                                          <h3><b>Nickname:</b> </h3>
                                          <span> {agentData.nickName}  </span>
                                    </div>

                                    <div className='agent-info-row'>
                                          <h3><b>Referral Code:</b></h3>
                                          <span>  {agentData.referralCode}  </span>
                                    </div>




                                    {/* Social Links Section */}
                                    <div className="social-links-section">

                                          <h3><b>Social Links:</b> <i className='txt-sm'>(Up to 6)</i></h3>

                                          {agentData.socialLinks.map((link, index) => (
                                                <div className='social-link-row social-link-row-hov' key={index + 'k'}>
                                                      <div key={index} id={'id-' + index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <p style={{ margin: 0 }}>
                                                                  {link.name}: <a href={link.link} target="_blank" rel="noopener noreferrer">{link.link}</a>
                                                            </p>

                                                            {/* Delete Button */}
                                                            <button
                                                                  onClick={() => removeSocialLink(link._id, index)}
                                                                  style={{ marginLeft: '10px', padding: '5px 10px', cursor: 'pointer', backgroundColor: '#ff4d4f', color: 'white', border: 'none', borderRadius: '4px' }}
                                                            >
                                                                  Delete
                                                            </button>
                                                      </div>
                                                </div>
                                          ))}

                                          <SendResultDisplay obj={removeLinkState} />


                                          {/* {agentData.socialLinks.map((link, index) => (
                                          <p key={index}>
                                                {link.name}: <a href={link.link} target="_blank" rel="noopener noreferrer">{link.link}</a>
                                          </p>
                                          
                                    ))} */}
                                          <div className='add-socila-link-container'>
                                                <div className='add-socila-link-frame'>
                                                      <b>Add Social Link:</b>
                                                      <br />
                                                      <br />
                                                      <div className='add-socila-link-box'>
                                                            <div className='add-socila-link-box-row'>

                                                                  <select
                                                                        value={newSocialLink.name}
                                                                        onChange={(e) => setNewSocialLink({ ...newSocialLink, name: e.target.value })}
                                                                  >
                                                                        <option value="" disabled>Select Platform</option>
                                                                        <option value="Instagram">Instagram</option>
                                                                        <option value="TikTok">TikTok</option>
                                                                        <option value="YouTube">YouTube</option>
                                                                        <option value="Telegram">Telegram</option>
                                                                        <option value="Snapchat">Snapchat</option>
                                                                        <option value="Facebook">Facebook</option>
                                                                        <option value="Medium">Medium</option>
                                                                        <option value="LinkedIn">LinkedIn</option>
                                                                        <option value="Discord">Discord</option>
                                                                  </select>

                                                            </div>
                                                            <div className='add-socila-link-box-row'>

                                                                  <input
                                                                        type="text"
                                                                        placeholder="Link"
                                                                        value={newSocialLink.link}
                                                                        onChange={(e) => setNewSocialLink({ ...newSocialLink, link: e.target.value })}
                                                                  />
                                                            </div>
                                                            <div className='add-socila-link-box-row'>
                                                                  <button onClick={addSocialLink} className='upd-btn'>Add Social Link</button>
                                                            </div>

                                                            <SendResultDisplay obj={addLinkState} />


                                                      </div>
                                                </div>
                                          </div>
                                          <br /><br />
                                          <div>
                                                <a target='_blank' href={'/agent/' + agentData.referralCode}> See my Public profile 🌐</a>
                                          </div>
                                    </div>


                              </div>
                              <div>
                                    <br />
                                    <hr />  <p>Private info: </p>
                                    <br />
                              </div>
                              {/* Display Agent Info */}
                              <div className="agent-info">
                                    <div className='agent-info-row'>
                                          <h3><b>Wallet Address:</b></h3>
                                          <span>  {agentData.walletAddress}</span>
                                    </div>


                                    <div className='agent-info-row'>
                                          <h3><b>Email:</b></h3>
                                          <span>  {agentData.email}  </span>
                                    </div>
                                    <div className='agent-info-row'>
                                          <h3><b>Telegram:</b> </h3>
                                          <span> {agentData.telegram || 'Not provided'}  </span>
                                    </div>

                                    <div className='agent-info-row'>
                                          <h3><b>Two-Factor Authentication:</b></h3>
                                          <span>    {agentData.twoFactorAuth ? 'Enabled' : 'Disabled'}</span>
                                    </div>

                              </div>


                              <div>
                                    <br />
                                    <br />
                                    <br />
                                    <hr />  <p>Editable info: </p>

                              </div>

                              {/* Editable Bio */}
                              <div className="bio-section">
                                    <h3>Bio <i className='txt-sm'>(Max 400)</i></h3>
                                    <div className='bio-btn-frame-input'>

                                          <textarea
                                                value={editableBio}
                                                onChange={(e) => setEditableBio(e.target.value)}
                                                rows="6"
                                          />
                                    </div>
                                    <div className='bio-btn-frame-btn'>

                                          <div className='bio-btn-frame'>
                                                <button onClick={updateBio} className='upd-btn'>Update Bio</button>

                                          </div>
                                    </div>
                                    {/* <div>
                                          {
                                                updateBioState.isSuccess ? <p className='res-success'>{updateBioState.msg}</p > : <p className='res-error'>{updateBioState.msg}</p>
                                          }
                                    </div> */}
                                    <SendResultDisplay obj={updateBioState} />


                              </div>


                              {/* Editable Referral code */}
                              <div className="bio-section">
                                    <h3>Referral code <i className='txt-sm'>(Min 4 - Max 10)</i></h3>
                                    <p className='txt-sm'>Changing referral code needed to be approved by moderators.</p>
                                    <div className='bio-btn-frame'>
                                          <div className='bio-btn-frame-input'>

                                                <input
                                                      value={editableReferralCode}
                                                      onChange={(e) => setEditableReferralCode(e.target.value)}
                                                      type="text"
                                                />
                                          </div>
                                          <div className='bio-btn-frame-btn'>

                                                <button onClick={updateReferralCode} className='upd-btn'>Request Update Referral Code</button>
                                          </div>
                                    </div>
                                    <SendResultDisplay obj={updateReferralCodeState} />
                              </div>


                              {/* Editable Telegram */}
                              <div className="bio-section">
                                    <h3>Telegram handle <i className='txt-sm'>(Max 100)</i></h3>
                                    <p className='txt-sm'>(Will not be published on public profile)</p>
                                    <div className='bio-btn-frame'>
                                          <div className='bio-btn-frame-input'>
                                                <input
                                                      value={editableTelegram}
                                                      onChange={(e) => setEditableTelegram(e.target.value)}
                                                      type="text"
                                                />
                                          </div>
                                          <div className='bio-btn-frame-btn'>
                                                <button onClick={updateTelegram} className='upd-btn'>Update Telegram</button>
                                          </div></div>
                                    <SendResultDisplay obj={updateTelegramState} />
                              </div>


                              {/* Change password Request */}
                              <div className="bio-section">
                                    <h3>Change Password <i className='txt-sm'></i></h3>
                                    <p className='txt-sm'>(Request to send a password reset link to your email)</p>
                                    <div className='bio-btn-frame'>

                                          <div className='bio-btn-frame-input'>
                                                <input
                                                      value={userEmail}
                                                      disabled={"disabled"}
                                                      type="text"
                                                />
                                          </div>
                                          <div className='bio-btn-frame-btn'>

                                                <button onClick={sendPasswordReset} className='upd-btn'>Send Password reset link</button>
                                          </div> </div>
                                    <SendResultDisplay obj={passwordChangeRequest} />
                              </div>

                              <div>
                                    <br />
                                    <br />
                                    <br />
                                    <hr />  <p>History: </p>
                              </div>

                              {/* Sale History Section */}
                              <div className="sale-history-section">
                                    <h3>Sale History</h3>
                                    {agentData.saleHistory.length > 0 ? (
                                          <ul>
                                                {agentData.saleHistory.map((sale, index) => (
                                                      <li key={index}>{sale}</li>
                                                ))}
                                          </ul>
                                    ) : (
                                          <p>No sales yet.</p>
                                    )}
                              </div>

                              {adminPanel ?
                                    <div>
                                          <br />
                                          <br />
                                          <br />
                                          <hr />  <p>Admin: </p>
                                          {adminPanel}
                                    </div>

                                    : <></>}
                        </div>
                  </div>
            </MainLayout>
      );
};

export default AgentDashboard;
