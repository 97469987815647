import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const GetAllPrivateAssets = ({ selector, deletor }) => {
      const [assets, setAssets] = useState([]);
      const [error, setError] = useState('');

      useEffect(() => {
            const fetchAssets = async () => {
                  try {
                        const response = await fetch(apiUrl + 'privateAsset/getAllAssets', {
                              method: 'GET',
                              headers: {
                                    'x-auth-token': localStorage.getItem('token')
                              }
                        });
                        const data = await response.json();
                        console.log(data);
                        setAssets(data);
                  } catch (err) {
                        console.log(err);
                        setError('Error fetching assets');
                  }
            };
            fetchAssets();
      }, []);

      return (
            <div>
                  <h2>All Private Assets</h2>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <table className='admin-table'>
                        <thead>
                              <tr>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Directory Path</th>
                                    <th>Course</th>
                                    <th>Action</th>
                              </tr>
                        </thead>
                        <tbody>
                              {assets.map(asset => (
                                    <tr key={asset._id}>
                                          <td>{asset.assetId}</td>
                                          <td>{asset.assetTitle}</td>
                                          <td>{asset.directoryPath}</td>
                                          <td>{asset.course}</td>
                                          <td>
                                                <button onClick={() => { selector(asset._id) }}>Update</button>
                                                &nbsp;|&nbsp;
                                                <button onClick={() => { deletor(asset._id, asset.assetTitle, asset.assetId) }}>Delete</button>
                                          </td>
                                    </tr>
                              ))}
                        </tbody>
                  </table>
            </div>
      );
};

export default GetAllPrivateAssets;
