import { useEffect, useState, useCallback } from "react"
import CourseCard from "./courseCard"
import * as Data from "../../../asset/js/data"

function CourseTable({ search }) {
      const [courses, setCourses] = useState([])
      const [ress, setress] = useState('')
      const [notInit, setnotInit] = useState(false)
      const [noRes, setnoRes] = useState(false)
      const [coursesDisplay, setCoursesDisplay] = useState([])
      const [section2, scrollToSection2] = useScrollTo();

      useEffect(() => {
            // console.log('noRes: ' + noRes)
            fetchCoursesLive()
      }, [])

      useEffect(() => {
            // console.log('noRes: ' + noRes)
            if (notInit) {
                  doFilter(search)
                  if (search == 'cancel-search'){
                        setCoursesDisplay(courses)
                        setress('')
                        setnoRes(false)
                  }
            }

      }, [search])

      async function fetchCoursesLive() {
            var liveCourses = await Data.fetchCourses()
            setCourses(liveCourses)
            setCoursesDisplay(liveCourses)
            setnotInit(true)
      }

      function doFilter(s) {
            scrollToSection2()
            console.log('do filter started.')
            var newCourseList = new Array()
            courses.forEach(co => {
                  if (co.title.toLowerCase().includes(s)) {
                        newCourseList.push(co)
                  }

                  co.technologiesUsed.forEach(tg => {
                        if (tg.toLowerCase().includes(s)) {
                              newCourseList.push(co)
                        }
                  });

            });

            if (newCourseList.length > 0) {
                  setnoRes(false)
                  console.log('do filter  length > 0.')
                  setress('Search results:  ')
                  let unique = [...new Set(newCourseList)];
                  setCoursesDisplay(unique)
                  // console.log('noRes: ' + noRes)


            } else {
                  setnoRes(true)
                  console.log('do filter  no course found.')

                  setCoursesDisplay(courses)

                  console.log('coursesDisplay: ')
                  console.log(coursesDisplay)
            }
      }
      function useScrollTo() {
            const id = 'topwin' //useId();
            const handleScroll = useCallback(() => {
                  const element = document.getElementById(id);
                  if (element) {
                        element.scrollIntoView(1000);
                  }
            }, [id]);

            return [id, handleScroll];
      }
      return (

            <>
                  {noRes ? <div className="footer-row-center"><p>No result for search <br />
                        see all courses:
                  </p></div> : <div className="footer-row-center"><p>{ress}

                  </p></div>}
                  <div className="course-frame">
                        {coursesDisplay.map(
                              (co, i) => (
                                    <CourseCard course={co}
                                          key={i}
                                    />
                              )
                        )}
                  </div>
            </>



      )
}


export default CourseTable