import React, { useState } from 'react';
import CreatePaidCourse from './createCourse';
import GetAllCourses from './allCourses';
import UpdatePaidCourse from './updatePaidCourse';
import DeletePaidCourse from './deleCourse';

const PaidCourseManagement = () => {
      const [view, setView] = useState('viewAll'); // Default view
      const [toEdit, setToEdit] = useState();  
      const [toDelete, setToDelete] = useState();  
      const [toDeleteVid, setToDeleteVid] = useState();  
      const [toDeleteTitle, setToDeleteTitle] = useState();  

      function selector(id) {
            console.log('Set selector to: '+id)
            setToEdit(id)
            setView('update')
      }
      function deletor(id,title,vid) {
            console.log('Set deletor to: '+id)
            setToDelete(id)
            setToDeleteVid(vid)
            setToDeleteTitle(title)
            setView('delete')
      }

      const renderComponent = () => {
            switch (view) {
                  case 'create':
                        return <CreatePaidCourse />;
                  case 'viewAll':
                        return <GetAllCourses selector={selector} deletor={deletor} />;
                  case 'update':
                        return <UpdatePaidCourse match={{ params: { id: toEdit } }} />; // Replace with actual course ID when needed
                  case 'delete':
                        return <DeletePaidCourse match={{ params: { id: toDelete, title:toDeleteTitle, vid:toDeleteVid } }} />; // Replace with actual course ID when needed
                  default:
                        return <CreatePaidCourse />;
            }
      };

      return (
            <div>
                  <h1>Paid Course Management</h1>
                  <div style={{ marginBottom: '20px' }}>
                        <button onClick={() => setView('create')}>Create Course</button>
                        <button onClick={() => setView('viewAll')}>View All Courses</button>
                        <button onClick={() => setView('update')}>Update Course</button>
                        <button onClick={() => setView('delete')}>Delete Course</button>
                  </div>
                  <div>{renderComponent()}</div>
            </div>
      );
};

export default PaidCourseManagement;
