import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const UpdatePrivateAsset = ({ match }) => {
      const [assetTitle, setAssetTitle] = useState('');
      const [assetId, setAssetId] = useState('');
      const [directoryPath, setDirectoryPath] = useState('');
      const [course, setCourse] = useState('');
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      useEffect(() => {
            const fetchAsset = async () => {
                  try {
                        const response = await fetch(apiUrl + `privateAsset/getAsset/${match.params.id}`, {
                              method: 'GET',
                              headers: { 'x-auth-token': localStorage.getItem('token') }
                        });
                        const data = await response.json();
                        setAssetTitle(data.assetTitle);
                        setAssetId(data.assetId);
                        setDirectoryPath(data.directoryPath);
                        setCourse(data.course);
                  } catch (err) {
                        console.log(err);
                        setError('Error fetching asset');
                  }
            };
            fetchAsset();
      }, [match.params.id]);

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  const updatedAsset = { assetTitle, assetId, directoryPath, course };
                  const response = await fetch(apiUrl + `privateAsset/updateAsset/${match.params.id}`, {
                        method: 'PUT',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(updatedAsset)
                  });
                  const data = await response.json();
                  console.log(data);
                  setSuccess('Asset updated successfully');
                  setError('');
            } catch (err) {
                  console.log(err);
                  setError('Error updating asset');
                  setSuccess('');
            }
      };

      return (
            <div>
                  <h2>Update Private Asset</h2>
                  <form onSubmit={handleSubmit} className='admin-form'>
                        <div>
                              <label>Asset ID</label>
                              <input
                                    type="text"
                                    value={assetId}
                                    onChange={(e) => setAssetId(e.target.value)}
                                    required
                              />
                        </div>
                        <div>
                              <label>Asset Title</label>
                              <input
                                    type="text"
                                    value={assetTitle}
                                    onChange={(e) => setAssetTitle(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Directory Path</label>
                              <input
                                    type="text"
                                    value={directoryPath}
                                    onChange={(e) => setDirectoryPath(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Course</label>
                              <input
                                    type="text"
                                    value={course}
                                    onChange={(e) => setCourse(e.target.value)}
                              />
                        </div>
                        <button type="submit">Update Asset</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
            </div>
      );
};

export default UpdatePrivateAsset;
