
const apiUrl = process.env.REACT_APP_apiUrl || 'http://127.0.0.1:3000/';
const state = process.env.REACT_APP_state

// USDT TRON contract address 
const trc20ContractAddress = 'TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj'; // Tron nile testnet
const trc20ContractAddressMainnet = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'; // Tron mainnet

function gettrc20ContractAddress(){
      
}

module.exports = { apiUrl, state, trc20ContractAddress }