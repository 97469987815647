import Loading from "../../loading";
import * as Data from "@as/js/data"

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { VideoHelmet } from "../template/helmet";
import { Helmet } from "react-helmet";
import PlayerWindow from "./playerComp";
import { useLocation, redirect } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
function VideoPlay() {

      const { id } = useParams();
      const { courseTitle } = useParams();
      const { videoTitle } = useParams();

      console.log('to fetch video id: ' + id + '   courseTitle: ' + courseTitle + '   videoTitle: '+videoTitle)

      const [video, setVideo] = useState()

      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      const navigate = useNavigate();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])

      async function fetchVideo() {
            //var courseVideo = await Data.getVideoAndCourseByTitle(id);
            var courseVideo;
            
            if(videoTitle && courseTitle){
                  courseVideo = await Data.getVideoAndCourseById(videoTitle, courseTitle);
            }else{
                  courseVideo = await Data.getVideoAndCourseById(id, courseTitle);
            }
            
            console.log()
            console.info(courseVideo)
            if (courseVideo == 0) {
                  alert('Couldn\'t find any video for given url. \n\n'+
                        'You\'ll be redirected to the course: "' + courseTitle +'"')
                  navigate('/course/' + courseTitle)

            }
            setVideo(courseVideo)

      }

      useEffect(() => {
            fetchVideo()
            const handleContextmenu = e => {
                  e.preventDefault()
            }
            document.addEventListener('contextmenu', handleContextmenu)
            return function cleanup() {
                  document.removeEventListener('contextmenu', handleContextmenu)
            }
      }, [])


      return (
            <>
                  {
                        video ?
                              <>
                                    <VideoHelmet video={video} />
                                     
                                    <PlayerWindow url={video.url} />

                              </> :
                              <Loading />

                  }
            </>
      )
}

export default VideoPlay