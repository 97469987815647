import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const CreatePrivateAsset = () => {
      const [assetTitle, setAssetTitle] = useState('');
      const [assetId, setAssetId] = useState('');
      const [directoryPath, setDirectoryPath] = useState('');
      const [course, setCourse] = useState('');
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  const newAsset = { assetTitle, assetId, directoryPath, course };
                  const response = await fetch(apiUrl + 'privateAsset/createAsset', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(newAsset)
                  });
                  const data = await response.json();
                  console.log(data);
                  setSuccess('Asset created successfully');
                  setError('');
            } catch (err) {
                  console.log(err);
                  setError('Error creating asset');
                  setSuccess('');
            }
      };

      return (
            <div>
                  <h2>Create Private Asset</h2>
                  <form onSubmit={handleSubmit} className='admin-form'>
                        <div>
                              <label>Asset ID</label>
                              <input
                                    type="text"
                                    value={assetId}
                                    onChange={(e) => setAssetId(e.target.value)}
                                    required
                              />
                        </div>
                        <div>
                              <label>Asset Title</label>
                              <input
                                    type="text"
                                    value={assetTitle}
                                    onChange={(e) => setAssetTitle(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Directory Path</label>
                              <input
                                    type="text"
                                    value={directoryPath}
                                    onChange={(e) => setDirectoryPath(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Course</label>
                              <input
                                    type="text"
                                    value={course}
                                    onChange={(e) => setCourse(e.target.value)}
                              />
                        </div>
                        <button type="submit">Create Asset</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
            </div>
      );
};
 export default CreatePrivateAsset;