import React, { useState } from 'react';
import CreatePrivateAsset from './createPrivateAsset';
import GetAllPrivateAssets from './getAllPrivateAssets';
import UpdatePrivateAsset from './updatePrivateAsset';
import DeletePrivateAsset from './deletePrivateAsset';

const PrivateAssetManagement = () => {
      const [view, setView] = useState('viewAll'); // Default view
      const [toEdit, setToEdit] = useState();
      const [toDelete, setToDelete] = useState();
      const [toDeleteVid, setToDeleteVid] = useState();
      const [toDeleteTitle, setToDeleteTitle] = useState();

      function selector(id) {
            console.log('Set selector to: ' + id)
            setToEdit(id)
            setView('update')
      }
      function deletor(id, title, vid) {
            console.log('Set deletor to: ' + id)
            setToDelete(id)
            setToDeleteVid(vid)
            setToDeleteTitle(title)
            setView('delete')
      }

      const renderComponent = () => {
            switch (view) {
                  case 'create':
                        return <CreatePrivateAsset />;
                  case 'viewAll':
                        return <GetAllPrivateAssets selector={selector} deletor={deletor} />;
                  case 'update':
                        return <UpdatePrivateAsset match={{ params: { id: toEdit } }} />;
                  case 'delete':
                        return <DeletePrivateAsset match={{ params: { id: toDelete, title: toDeleteTitle, vid: toDeleteVid } }} />;
                  default:
                        return <CreatePrivateAsset />;
            }
      };

      return (
            <div>
                  <h1>Private Asset Management</h1>
                  <div style={{ marginBottom: '20px' }}>
                        <button onClick={() => setView('create')}>Create Private Asset</button>
                        <button onClick={() => setView('viewAll')}>View All Private Assets</button>
                        <button onClick={() => setView('update')}>Update Private Asset</button>
                        <button onClick={() => setView('delete')}>Delete Private Asset</button>
                  </div>
                  <div>{renderComponent()}</div>
            </div>
      );
};

export default PrivateAssetManagement;
