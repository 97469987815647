import { useState } from "react"

function HomeBigTitle({finder}) {
      const [sbv,setsbv] = useState('')
      const [key, setKey] = useState("");
      function sendFind() { finder(sbv) }
      function cancelFind() { finder('cancel-search') }

      const handleKeyDown = (event) => {
            setKey(event.key);
            console.log('event.key: ' + event.key)
            if (event.key =="Enter"){
                  sendFind()
            }
            if (event.key =="Escape"){
                  cancelFind()
                  setsbv('')

            }
      };
      // function handleInput(e) { setsbv(e.target.value)}
      return (
            <> 
                  <div className="home">
                        <div className="home-inside">
                              <h1 className="title-main">Find a Perfect Course <br />
                                    Learn Everything Online <br />
                                    Build Your Skills</h1>
                              <div className="searchbar-frame" id="topwin">

                                    <div className="searchbar-input">
                                          <input type="text" value={sbv}  placeholder="Crypto Wallet, Bitcoin, node.js ......." className="searchbar-input-text" 
                                                onInput={e => setsbv(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                    //      onInput={handleInput}
                                     />
                                          <button className="searchbar-input-btn" onClick={sendFind}>Find</button>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      )
}
export default HomeBigTitle