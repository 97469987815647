import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import MainLayout from "../../template/MainLayout"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import { Redirect } from 'react-router-dom';
const AgentLogin = () => {
      const navigate = useNavigate();
      const [myRedirectState, setMyRedirectState] = useState('')

      const location = useLocation();

      useEffect(() => {
            let str = location.pathname

            console.log(location.pathname)
            setMyRedirectState(str.substring(7, str.length))
            if (location.pathname == '/agent/login/fromVerifySuccess'){
                  setSuccessMessage('Your Email has beenVerified Successfully.')
            }
            if (location.pathname == '/agent/login/fromResetSuccess'){
                  setSuccessMessage('Password has been reset successfully.')
            }
      }, [myRedirectState])



      // Define form data and error state
      const [formData, setFormData] = useState({
            email: '',
            password: ''
      });

      const [errors, setErrors] = useState([]);
      const [successMessage, setSuccessMessage] = useState('');

      const { email, password } = formData;

      // Handle input changes
      const onChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      // Handle form submission
      const onSubmit = async (e) => {
            e.preventDefault();

            try {
                  // Send POST request using fetch
                  const response = await fetch(apiUrl + 'agent/login', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                  });

                  const resData = await response.json();

                  if (response.ok) {
                        // Successful response, save token or handle as needed
                        localStorage.setItem('token', resData.token); // Example of storing token
                        setSuccessMessage('Login successful!');
                        setErrors([]); // Clear errors on success
                        // Redirect.("./agent/dashboard")
                        // this.props.history.push('/dashboard')
                        navigate("/agent/dashboard");
                  } else {
                        // If there are validation errors
                        setErrors(resData.errors || [{ msg: resData.msg }]);
                        // setErrors(resData.errors || [{ msg: 'Server error' }]);
                        console.log(resData)

                  }

            } catch (err) {
                  // Handle general errors
                  console.log(err)
                  setErrors([{ msg: 'Server error, please try again later.' }]);
            }
      };

      return (
            <MainLayout>
                  <div className="form-container">
                        <h2>Login as an Agent</h2>

                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {successMessage && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>{successMessage}</p>
                              </div>
                        )}

                        <form onSubmit={onSubmit}>
                              <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                          type="email"
                                          name="email"
                                          value={email}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                    />
                              </div>

                              <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                          type="password"
                                          name="password"
                                          value={password}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                    />
                              </div>

                              <button type="submit" className="reg-btn">Login</button>
                              <div className='already'>
                                    Don't have an account yet?
                                    <br /> Go to <a href="./agent/register">Register</a>
                              </div>
                              <br />
                              <div className='already'>
                                    Trouble entering? Go to <br /> <a href="./agent/forgotPassword">Forgot Password</a>
                              </div>
                              <br /><br />
                        </form>
                  </div>
            </MainLayout>
      );
};

export default AgentLogin;
