import React  from 'react';

const SendResultDisplay = ({obj}) => {
      return(
            <div>
                  {obj.isSuccess ? <p className='res-success'>{obj.msg}</p > : <p className='res-error'>{obj.msg}</p>}
            </div>
      )
}

export default SendResultDisplay