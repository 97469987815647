function PlayerWindow({ url }) {
      return (
            <div className="video-container">
            <video autoPlay controls className="vp" controlsList='nodownload' >
                  <source src={url} />
            </video>
            </div>
      )
}

export default PlayerWindow