// This module used to develop user data on client side.
// It make it easier to swith between local storage and cookie
import Cookies from 'universal-cookie';

const apiURL = await process.env.REACT_APP_apiUrl

export async function getUserData(key) {
      //console.log(localStorage.getItem('key'))
      return localStorage.getItem(key)
}

export async function setUserData(key, value) {
      localStorage.setItem(key, value)
}


const cookies = new Cookies();

export async function getUser() {
      var userToken = await cookies.get('M-6t-user-');
      return userToken;
}

export async function getMyCourses(walletAddress, courseId) {
      console.log('From userData > getMyCourses - start - wallet address: ' + walletAddress)
      // var courseToken = await cookies.get('M-8t-course-');
      var courseToken = await getCourseAccessToken(walletAddress, courseId)
      console.log(courseToken)
      console.log(typeof (courseToken))
      if (courseToken.status == 'sign') {
            //getCourseAccessToken(walletAddress)
            return courseToken
      } else if (courseToken.status == 'error') { return courseToken } {
            let result = await fetch(apiURL + 'course/me', {
                  method: 'get',
                  headers: {
                        'Content-Type': 'application/json',
                        'x-course-token': courseToken.token
                  }
            });

            console.log(result)

            let accessResult = await result.json()
            console.log('From userData > getMyCourses - call results:')
            console.log(accessResult)
            if (accessResult.status == 'success') return accessResult.data
            if (accessResult.status == 'error') return accessResult

      }
}

async function getCourseAccessToken(walletAddress, courseId) {
      try {

            var courseToken = await cookies.get('M-8t-course-');
            if (courseToken == undefined || courseToken == null
                  || courseToken == 'undefined' || courseToken == '') {
                  console.log(apiURL + 'access/gettoken/' + walletAddress + '/' + courseId)

                  let callForToken = {}
                  try {


                        await fetch(apiURL + 'access/gettoken/' + walletAddress + '/' + courseId).then((res) => {
                              callForToken = res
                        }).catch((err) => {
                              console.log('userData.js - getCourseAccessToken Catch - Error:')
                              console.log(err)
                        })


                        console.info(callForToken)
                        let result = await callForToken.json()
                        console.log(result)
                        if (result.status == 'error') {
                              // alert(result.msg)
                              return { status: 'error', msg: result.msg }

                        }
                        if (result.status == 'success') {

                              return { status: 'sign', message: result.message, time: result.validUntil }
                        }
                  } catch (error) {
                        console.log('Extra catch')
                        console.log(error)
                  }


            } else {
                  return { status: 'exist', token: courseToken }

            }

      } catch (error) {

      }
}

export async function saveSignature(signature, time, walletAddress) {
      try {

            console.log('On save signature')
            let callForToken = await fetch(`${apiURL}access/validate/${walletAddress}/${signature}`)
            // let callForToken = await fetch(apiURL + 'access/validate/' + walletAddress)
            console.info(callForToken)
            let result = await callForToken.json()
            console.log(result)
            let d = new Date(time);
            //console.log('from setBookmarkOnCookie type of inp: ' + typeof (inp))
            console.log(typeof (time))
            // d.setTime(time);
            console.log(d)
            cookies.set('M-8t-course-', result.data.accessToken, { path: "/", expires: d });
            return await getMyCourses(walletAddress)

      } catch (error) {
            alert(error.message)
            return { status: 'error', msg: error.message }
      }
}


export async function saveSignatureAndGetVideo(signature, time, walletAddress, videoToken) {
      console.log('On save signature')
      let callForToken = await fetch(`${apiURL}access/validate/${walletAddress}/${signature}`)
      // let callForToken = await fetch(apiURL + 'access/validate/' + walletAddress)
      console.info(callForToken)
      let result = await callForToken.json()
      console.log(result)
      let d = new Date(time);
      //console.log('from setBookmarkOnCookie type of inp: ' + typeof (inp))
      console.log(typeof (time))
      // d.setTime(time);
      console.log(d)
      cookies.set('M-8t-course-', result.data.accessToken, { path: "/", expires: d });
      //getMyCourses(walletAddress)
      getVideo(walletAddress, videoToken)
}

export async function getVideo(walletAddress, videoTitle) { // videoTitle was videoToken
      try {
            var courseToken = await getCourseAccessToken(walletAddress)
            console.log(courseToken)
            console.log(typeof (courseToken))
            if (courseToken.status == 'sign') {
                  //getCourseAccessToken(walletAddress)
                  return courseToken
            } else {
                  console.log('on userData - getVideo - videoTitle:')
                  console.log(videoTitle)

                  let result = await fetch(apiURL + 'course/getvideolink/' + videoTitle, {
                        method: 'get',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-course-token': courseToken.token
                        }
                  })

                  console.log(result)

                  let accessResult = await result.json()
                  console.log('From userData > getVideo - call results:')
                  console.log(accessResult)
                  return accessResult
            }
      } catch (error) {
            alert(error.message)
      }
}


export async function delayedProcessCheckRequest() {
      try {
            setTimeout(async () => {
                  await fetch(apiURL + 'purchase/processing').catch((err) => {
                        console.log(" Error in delayedProcessCheckRequest - userData.js - error:")

                        console.log(err)

                  })
            }, 3000);
      } catch (error) {

      }
}

export async function checkDiscountCode(code) {
      return await fetch(apiURL + 'purchase/checkdiscount/' + code)
}

export async function getContractAddress() {
      return await fetch(apiURL + 'purchase/contractAddress/')
}

export async function getPurchaseSettings() {
      return await fetch(apiURL + 'purchase/settings/')
}

export async function checkDiscountCodeOld(code) {
      //here must call api and ask for dicount code validation and portion

      // try {
      //       setTimeout(async () => {
      //             await fetch(apiURL + 'purchase/checkdiscount/' + code).then(async (res)=>{
      //                   console.log(res)
      //                   let r2 = await res.json()
      //                   console.log(r2)


      //             }).catch((err)=>{
      //                   console.log('Catched error in check discount code. error:')
      //                   console.log(err)
      //             })
      //       }, 100);
      // } catch (error) {

      // }

      return await fetch(apiURL + 'purchase/checkdiscount/' + code)
      // just as dev test:
      // if (code == 'ssdd') return { status: 'success', portion: '0.5', message: '' }
      // return { status: 'error', portion: '0' , message:'Code is expired'}


}