import React from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const DeletePrivateVideo = ({ match }) => {
      const handleDelete = async () => {
            try { 
                  const response = await fetch(apiUrl + `privateVideo/deleteVideo/${match.params.id}`, {
                        method: 'DELETE',
                        headers: { 'x-auth-token': localStorage.getItem('token') }
                  });
                  const data = await response.json();
                  console.log(data);
                  alert(`Video titled '${match.params.title}' with ID ${match.params.vid} has been deleted.`);
            } catch (err) {
                  console.log(err);
                  alert('Error deleting video');
            }
      };

      return (
            <div>
                  <h2>Are you sure you want to delete this video?</h2>
                  <p>Title: {match.params.title}</p>
                  <p>Video ID: {match.params.vid}</p>
                  <button onClick={handleDelete}>Yes, Delete</button>
            </div>
      );
};

export default DeletePrivateVideo;
