import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import MainLayout from "../../template/MainLayout"
import {  useEffect } from "react"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AgentResetPassword = () => {
      const [myState, setMyState] = useState('initialState')
      const navigate = useNavigate();

      const location = useLocation();
      useEffect(() => {
            let str = location.pathname

            console.log(location.pathname)
            setMyState(str.substring(21,str.length))
      }, [myState])
      console.log(myState)


      // Define form data and error state
      const [formData, setFormData] = useState({
            
            password: ''
      });

      const [errors, setErrors] = useState([]);
      const [successMessage, setSuccessMessage] = useState('');

      const {  password } = formData;

      // Handle input changes
      const onChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      // Handle form submission
      const onSubmit = async (e) => {
            e.preventDefault();

            try {
                  let reset_token = myState
                  // Send POST request using fetch
                  const response = await fetch(apiUrl + 'agent/Reset-Password/'+reset_token, {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                  });

                  const resData = await response.json();

                  if (response.ok) {
                        // Successful response, save token or handle as needed
                        // localStorage.setItem('token', resData.token); // Example of storing token
                        setSuccessMessage('Password Reset successful!');
                        console.log(resData)
                        navigate("/agent/login/fromResetSuccess");

                        setErrors([]); // Clear errors on success
                  } else {
                        // If there are validation errors
                        console.log(resData)
                        setErrors(resData.errors || [{ msg: 'Server error' }]);
                  }

            } catch (err) {
                  // Handle general errors
                  setErrors([{ msg: 'Server error, please try again later.' }]);
                  console.log(err)

            }
      };

      return (
            <MainLayout>
                  <div className="form-container">
                        <h2> Agent - Reset Password</h2>

                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {successMessage && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>{successMessage}</p>
                              </div>
                        )}

                        <form onSubmit={onSubmit}>
                               
                              <div className="form-group">
                                    <label htmlFor="password">New Password</label>
                                    <input
                                          type="password"
                                          name="password"
                                          value={password}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                          autoComplete="new-password"
                                    />
                              </div>

                              <button type="submit" className="reg-btn">Send</button>
                        </form>
                  </div>
            </MainLayout>
      );
};

export default AgentResetPassword;
