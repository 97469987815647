import MainLayout from "../template/MainLayout"
import { useLocation } from "react-router-dom";
import { recordVisit, getGeneralCookie } from "../../../asset/js/site-cookies"
import { useEffect, useState } from "react";
import { apiUrl } from '../../../asset/js/allStatics'
import { Helmet } from "react-helmet";

import SendResultDisplay from '../agent/agentSubs/subComponents/sendResultDisplay'
// import SendResultDisplay from '../agent/agentSubs/subComponents/sendResultDisplay'

function ReportBug() {

      const [myState, setMyState] = useState('initialState')
      const [userQueryMessagePH1, setUserQueryMessagePH1] = useState('Hi.\n');
      const [sendMessageState, setSendMessageState] = useState({});
      const [sendDisabled, setSendDisabled] = useState(false)
   
      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])


      // Handle Message phase 1
      const sendMessage = async () => {
            setSendDisabled(true)
            console.log(userQueryMessagePH1.length)

            let messageData = {
                  message: userQueryMessagePH1,
                  from: location.pathname,
                  userid: await getGeneralCookie()
            }

            try {
                  const res = await fetch(apiUrl + 'qwph1/sendquerymessage', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              // 'x-auth-token': localStorage.getItem('token')

                        },
                        body: JSON.stringify(messageData)
                  });
                  const data = await res.json();
                  console.log('query wall - data: ')
                  console.log(data)

                  if (res.ok) {
                        console.log('res is ok')
                        setSendMessageState({ msg: 'Message has been sent successfully', isSuccess: true });
                         

                  } else {
                        console.log('res is NOT ok')
                        setSendMessageState({ msg: 'Could not send the message', isSuccess: true });
                        setSendDisabled(false)

                  }
            } catch (error) {
                  console.log('Server error while sending message')
                  setSendMessageState({ msg: 'Unable t send the message', isSuccess: true });

            }
      };

      var content = {
            url: 'https://melarix.com/report-bug',
            title: 'Report a Bug or Issue - Help Us Improve Melarix',
            description: 'Encountered a problem on the Melarix website? Report technical glitches, broken links, or other issues to help us enhance your experience.',
            type: 'website',
            image: 'https://melarix.com/melarix-mascot.png'
      }


      return (
            <MainLayout>
                  <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />

                        <link rel="canonical" href={content.url.toString()} />

                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>
                  <>
                        <div className="page-body">
                              <div className="page-head">
                                    <h1> Report a Bug or Issue</h1>
                                    {/* <h1>🚀 Introducing QueryWall: Your Learning Support Hub!</h1> */}
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>🛠 Spotted a problem on the website?</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                       Whether it's a technical glitch, a broken link, or anything else that needs fixing, we appreciate your feedback!
                                                </p>

                                          </div>
                                    </div>
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>How to Report an Issue</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      <b>Email Us</b>     - Send a detailed description of the issue to <a href="mailto:matthewshelb@gmail.com" className="foot-link">MatthewShelB@gmail.com</a>.
                                                </p><p>
                                                      <b>Use QueryWall</b>    - Drop a quick message on the current QueryWall, and we'll look into it.
                                                </p><p>
                                                      We're always working to improve your experience. Thanks for helping us make the site better! 
                                                </p> 

                                          </div>
                                    </div>

                                  
                              </div>
                        </div>

                  </>
            </MainLayout >
      )
}

export default ReportBug