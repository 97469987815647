import Cookies from 'universal-cookie';
import { apiUrl } from './allStatics'

const cookies = new Cookies();

async function getGeneralCookie(ref) {
      console.log('getGeneralCookie - ref: ' + ref)

      var userToken = await cookies.get('M-6t-user-');
      if (userToken == undefined || userToken == null) {
            console.log('getGeneralCookie inside the if #1 - ref: ' + ref)

            if (ref == undefined || ref == null) {
                  ref = 'public_user'
                  console.log('getGeneralCookie inside the if #2 - ref: ' + ref)

            }
            let newToken = uuidv4() + '---' + ref;
            console.log(newToken)
            console.log('getGeneralCookie inside the if #3 - ref: ' + ref)

            let d = new Date();
            //console.log('from setBookmarkOnCookie type of inp: ' + typeof (inp))
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            cookies.set('M-6t-user-', newToken, { path: "/", expires: d });
            return newToken;
      } else {
            console.log('user token exists')
            return userToken;
      }
}

async function recordVisit(path, ref) {
      console.log('recordVisit - ref: ' + ref)
      var user = await getGeneralCookie(ref)

      console.log('User: ' + user + '  -visit: ' + path + '  -ref: ' + ref)
      const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user: user, path: path })
      };

      fetch(apiUrl + 'recordvisit', requestOptions)
            .then(response => response.json())
            .then(data => {
                  console.log(data);
            })
            .catch(error => {
                  console.error('Error:', error);
            });

}

function uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
}

async function setRefCookie(code) {
      var refToken = await cookies.get('M-5t-ref-');
      if (refToken == undefined || refToken == null) {

            console.log('To ref code new: ' + code)
            let d = new Date();
            //console.log('from setBookmarkOnCookie type of inp: ' + typeof (inp))
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            cookies.set('M-5t-ref-', code, { path: "/", expires: d });
            return code;
      } else {
            console.log('To ref code exist: ' + refToken)

            return refToken
      }
}

async function subscribeLimitation() {
      var num = await cookies.get('M-4t-subnum-')
      if (num == undefined || num == null) {
            num = 0;
            let d = new Date();
            //console.log('from setBookmarkOnCookie type of inp: ' + typeof (inp))
            d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
            cookies.set('M-4t-subnum-', num, { path: "/", expires: d });
            return Number(num);
      } else {
            num++;
            console.log('Total subscribe ' + num)
            let d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            cookies.set('M-4t-subnum-', num, { path: "/", expires: d });
            return Number(num)
      }
}


var isSending = false;
async function subscribe(email, loc) {
      if (!isSending) {
            isSending = true
            console.log('subscribe start  - email: ' + email)
            var user = await getGeneralCookie('')
            let count = await subscribeLimitation()
            if (count > 4) {

                  return true
            }
            console.log('User: ' + user + '  -email: ' + email)
            const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ user: user, email: email, location: loc })
            };

            return await fetch(apiUrl + 'newemail', requestOptions)
                  .then(response => response.json())
                  .then(data => {
                        console.log(data);
                        isSending = false
                        return data
                  })
                  .catch(error => {
                        console.error('Error:', error);
                        isSending = false
                        return error
                  });
      }
}


var isSendingQWM = false;
async function sendQWM(massage) {
      if (!isSendingQWM) {
            isSendingQWM = true
            console.log('isSendingQWM start ')
            var user = await getGeneralCookie('')
            let count = await QWMLimitation()

            if (count > 2) {
                  return { status: 'error', msg: 'Too many messages in a day.' }
            }

            let messageData = {
                  message: massage,
                  from: 'querywall',
                  userid: user
            }

            return await fetch(apiUrl + 'qwph1/sendquerymessage', {
                  method: 'POST',
                  headers: {
                        'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(messageData)
            }).then(async (res) => {
                  return await res.json()
            }).catch(async (err) => {
                  console.log('site-cookie        sending message error:')
                  console.log(err)
                  return await err.json()
            });
      }
}



async function QWMLimitation() {
      var num = await cookies.get('M-4t-qwm-')
      if (num == undefined || num == null) {
            num = 0;
            let d = new Date();
            //console.log('from setBookmarkOnCookie type of inp: ' + typeof (inp))
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            cookies.set('M-4t-qwm-', num, { path: "/", expires: d });
            return Number(num);
      } else {
            num++;
            console.log('Total qwm ' + num)
            let d = new Date();
            d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
            cookies.set('M-4t-qwm-', num, { path: "/", expires: d });
            return Number(num)
      }
}


function validateEmail(emailField) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (reg.test(emailField.value) == false) {
            console.log('Invalid Email Address');
            return false;
      }

      return true;

}


export async function getListForPageOpen(pahtname) {
      var page = await cookies.get('M-4t-page-' + pahtname);
      return page
}
export function setListForPageOpen(pahtname, val) {
      console.log('site-cookies.js val: ' + val)
      let d = new Date();
      d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
      cookies.set('M-4t-page-' + pahtname, val, { path: "/", expires: d });
}

export { getGeneralCookie, recordVisit, setRefCookie, subscribe, validateEmail, sendQWM }