//#region imports
// import bookmark from "../../../asset/image/Icons/bx-bookmark.svg"
// import bookmarkFilled from "../../../asset/image/Icons/bxs-bookmark.svg"
import { useEffect, useState } from "react";


import DifficultyLevelBox from "./singleCoursePage/subComponent/difficulityLevelBox"
import BookmarkSign from "./singleCoursePage/subComponent/bookmarkSign"
import CourseType from "./singleCoursePage/subComponent/courseType"

import time from "../../../asset/image/Icons/bx-time.svg"
import info from "../../../asset/image/Icons/bxs-info-circle.svg"
import btcCoin from "../../../asset/image/coin/BTC.png"
import usdtCoin from "../../../asset/image/coin/USDT.png"
//
import { convertDuration, convertDurationString } from '../../../asset/js/custom'
// import { getAllBookmarkedCoursesFromCookie, toggleBookmark } from '../../../asset/js/bookmark'
import { Link } from "react-router-dom";
// import { useState, useRef, useEffect } from 'react'

//#endregion
function getCoinBySymbol(symbol) {
      if (symbol == "BTC") return btcCoin
      if (symbol == "USDT") return usdtCoin
}
function getLastCharacters(str, n) {
      let newString = str.substring(str.length - n);
      return newString;
}
function getFirstCharacters(str, n) {
      let newString = str.substring(0, str.length - n);
      return newString;
}
function CourseCard({ course, altKey, handleClick, fReff }) {
      // console.log('---  CourseCard   Started: ')
      // console.info(course)

      //card-banner card-banner-mask
      const [imgClass, setImgClass] = useState('card-banner card-banner-mask')
      useEffect(() => {
            if (course.shortTitle == 'Bitcoin Wallet Development') {
                  setImgClass('card-banner card-banner-mask-light')
            }
            // 
      })

      let sufix = getLastCharacters(course.image, 4)
      let base = getFirstCharacters(course.image, 4)
      let smImage = base + '-sm' + sufix
      //console.log('from card --- smImage: ' + smImage)

      return (
            <div className="card-frame">
                  <div className="card-border">
                        <img className={imgClass} src={smImage} alt={course.shortTitle + ' - course image'} />
                        <div className="card-title-frame">
                              <div className="card-title">
                                    {course.shortTitle}
                              </div>
                        </div>
                        <div className="card-shortDescription-frame">
                              <div className="card-shortDescription">

                                    {course.shortDescription}
                              </div>
                        </div>
                        <div className="techs-bar" title="Technolgies Used in the course">
                              {course.technologiesUsed.map((keyword, i) => (
                                    <div className="techs" key={i}>{keyword}</div>
                              ))}
                        </div>

                        <div className="card-info-bar">
                              <DifficultyLevelBox dif={course.difficultyLevel} />
                              <div className="card-info" title="Duration"><span><img className="info-icon" src={time} alt="Course duration icon" /></span> <span title={convertDurationString(course.duration)}> {convertDuration(course.duration)}  </span>
                              </div>
                              {/* <div className="card-info" title="Duration"><span><img className="info-icon" src={time} alt="Course duration icon" /></span> {convertDuration(course.duration)}
                              </div> */}
                              <div className="card-info" title="Course Type"><span><img className="info-icon" src={info} alt="Course type icon" /></span> <CourseType courseTypeIndex={course.courseType} /> </div>
                        </div>

                        <div className="card-foot-bar">
                              {course.isAccessibleForFree ? (
                                    <div className="card-foot-price-frame" title="Course Is Accessaible for free">

                                          <div className="card-foot-price" title="Course price"> Free 

                                          </div>
                                    </div>
                              ) : (
                                    <div className="card-foot-price-frame" title="Course price in BTC">

                                          {course.acceptedPayments.map((payment, i) => (
                                                // <div className="techs" key={i}>{keyword}</div>

                                                <div key={i} className="card-foot-price" title={`Price in ${payment.symbol} payable on the ${payment.chain} blockchain`}>
                                                      <div className="card-foot-price-logo">
                                                            <img className="btcCoin" src={getCoinBySymbol(payment.symbol)} alt="Coin logo" /></div>
                                                      <div className="card-foot-price-text">{payment.amount}</div>
                                                </div>
                                          ))}


                                    </div>
                              )}
                              {/* <div className="read-ribbon"> */}
                              {/* <div className="card-foot-ribbon" title="Mark it for later">
                                          <img id={"bm" + key}
                                                // onClick={toggleBookmark('001')}
                                                src={bookmark} alt="" className="empty" />
                                    </div> */}
                              {/* <BookmarkBtn courseId={course.id} parentKey={altKey} handle={handleClick} ref={fReff} /> */}
                              <div className="card-foot-Readmore" title="See full description">
                                    <Link className="card-foot-Readmore-link" target="_blank" to={{
                                          pathname: `/course/${course.title}`

                                    }}  >Read More</Link>

                              </div>
                              <BookmarkSign courseId={course.id} />

                              {/* </div> */}
                        </div>
                  </div>
            </div>

      )
}



export default CourseCard
