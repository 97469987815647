import MainLayout from "../template/MainLayout"
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";


import img1 from "../../../asset/image/Courses/test-how/Purchase1.png"
import img2 from "../../../asset/image/Courses/test-how/Purchase2.png"
import img3 from "../../../asset/image/Courses/test-how/Purchase3.png"
import img4 from "../../../asset/image/Courses/test-how/Purchase4.png"
import img5 from "../../../asset/image/Courses/test-how/Purchase5.png"
import img6 from "../../../asset/image/Courses/test-how/Purchase6.png"
import img7 from "../../../asset/image/Courses/test-how/Purchase7.png"
import img8 from "../../../asset/image/Courses/test-how/Purchase8.png"
// import zipFile from "../../../asset/image/Courses/"




function HowToPurchase() {
      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])

      var content = {
            url: 'https://melarix.com/how_to_purchase',
            title: 'How to Purchase - Buy Courses on Melarix with Cryptocurrency',
            description: 'See how to purchase courses on Melarix using cryptocurrency. Follow a step-by-step guide to complete your transaction securely.',
            type: 'website',
            image: 'https://melarix.com/melarix-mascot.png'
      }


      return (
            <MainLayout>
                  <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />

                        <link rel="canonical" href={content.url.toString()} />

                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>
                  <>
                        <div className="page-body">
                              <div className="page-head">
                                    <h1>How to Purchase a Course</h1>
                                    <p>
                                          Our courses are available for purchase using cryptocurrency. To complete your purchase, you will need:

                                          <li>    A supported crypto wallet (installed and unlocked in your browser, e.g. TronLink)

                                          </li> <li>  Sufficient funds to cover the course price and transaction fees

                                          </li>

                                          Follow the steps below to complete your purchase successfully.
                                    </p>
                              </div>



                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>1. Initiating the Purchase Process </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Click on the payment option you want to use to buy the course.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img1} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>



                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>2. Open Your Wallet</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Make sure your wallet is unlocked, Connected to the TronGrid Network, and has enough funds to pay:
                                                      <li> 1. TronGrid Network </li>
                                                      <li> 2. For Transaction Fee</li>
                                                      <li>3. For Course Price</li>
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img2} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>



                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>3. Confirm Your Purchase</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Read and agree to the terms of use by checking the box. Then, click the "Confirm and Pay" button.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img3} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>



                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 4. Sign the Transaction</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      A wallet pop-up will appear. Review the transaction details and sign to authorize payment.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img4} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>




                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 5. Wait for Transaction Processing</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      If the transaction is successful, you will see a confirmation message.
                                                      <br />Click OK and wait a few seconds for the transaction to be processed.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img5} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>




                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 6. Unlock Your Course                                                     </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Go to the end of the videos list and click on the "Unlock the Course" button.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img6} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>




                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 7. Sign the Access Message</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>


                                                      Signing the message is free and grants access to the course for 24 hours.
                                                      <br />After that, you will need to sign again to continue accessing the content.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img7} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>




                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 8. Start Watching</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Once signed, the locked icon will change to an unlocked icon, allowing you to watch the videos.
                                                </p>
                                                <div className="purchase-guide-step-img-frame">
                                                      <img src={img8} alt="" className="purchase-guide-step-img" />
                                                </div>
                                          </div>
                                    </div>
                              </div>


                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> FAQ</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>   <b>  How can I pay with my wallet on my phone?  </b></p>
                                                <p>Currently, you cannot pay directly from a mobile wallet. You will need to import your wallet into a browser extension to complete the process. </p>
                                                <br /><br />
                                                <p>  <b> Can I pay with other cryptocurrencies?  </b>  </p>
                                                <p>     No. Payments must be made using the provided payment option. The network and cryptocurrency contract address are predefined for each payment. </p>

                                                <br /><br />

                                                <p>   <b>  Can I download the videos? </b> </p>
                                                <p>    No, downloading is not allowed. You must sign and access the videos directly on our platform.
                                                      <br />
                                                      Once signed, the locked icon will change to an unlocked icon, allowing you to watch the videos.
                                                </p>


                                          </div>
                                    </div>
                              </div>




                        </div>








                  </>
            </MainLayout>
      )
}

export default HowToPurchase