import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import MainLayout from "../../template/MainLayout"
import { useEffect } from "react"
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const AgentVerifyEmail = () => {
      const [myState, setMyState] = useState('initialState')
      const navigate = useNavigate();

      const location = useLocation();
      useEffect(() => {
            let str = location.pathname
            console.log(location.pathname)
            let verToken = str.substring(19, str.length)
            verifyEmail(verToken)
            setMyState(verToken)

      }, [myState])
      console.log(myState)

      const verifyEmail = async (token) => {
            try {


                  // Send GET request using fetch
                  const response = await fetch(apiUrl + 'agent/verifyEmail/' + token, {
                        method: 'GET',
                        headers: {
                              'Content-Type': 'application/json',
                        },

                  });

                  const resData = await response.json();

                  if (response.ok) {
                        // Successful response, save token or handle as needed
                        // localStorage.setItem('token', resData.token); // Example of storing token
                        // setSuccessMessage('Login successful!');
                        console.log(resData)
                        if (resData.status == 'success') {


                              navigate("/agent/login/fromVerifySuccess");

                        }
                        setErrors([]); // Clear errors on success
                  } else {
                        // If there are validation errors
                        console.log(resData)
                        setErrors(resData.errors || [{ msg: 'Server error - ' + (resData.msg || '') }]);
                  }

            } catch (err) {
                  // Handle general errors
                  setErrors([{ msg: 'Server error, please try again later. ' + (err.msg || '') }]);
                  console.log(err)

            }
      }


      // Define form data and error state
      const [formData, setFormData] = useState({

            password: ''
      });

      const [errors, setErrors] = useState([]);
      const [successMessage, setSuccessMessage] = useState('');



      return (
            <MainLayout>
                  <div className="form-container ">
                        <h2> Agent - Verify Email</h2>

                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {successMessage && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>{successMessage}</p>
                              </div>
                        )}


                  </div>
                  <div className="ps-note">
                        <p>If you have problem to access your account, please visit <a href="/agent/resend/">Request Resend Email</a> for a new verificatin or password reset link </p>
                  </div>
            </MainLayout>
      );
};

export default AgentVerifyEmail;
