import Loading from "../../loading";
import * as Data from "@as/js/data";
import mv2 from "@as/image/Icons/mv2.svg"
import loadingIcon from "@as/image/Icons/waiting2.svg"

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import PlayerWindow from "./playerComp";
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { getVideo, saveSignatureAndGetVideo } from "../../../asset/js/userData";
import MainLayout from "../template/MainLayout";
function VideoPlayLocked() {

      const { id } = useParams();
      const { courseTitle } = useParams();
      const { videoTitle } = useParams();
      const { accessToken } = useParams();

      console.log('to fetch locked video id: ' + id + '   courseTitle: ' + courseTitle + '   videoTitle: ' + videoTitle + '   accessToken: ' + accessToken)

      const [video, setVideo] = useState()
      const [videoInit, setVideoInit] = useState(false)
      const [videoElement, setVideoElement] = useState(false)
      const [init, setInit] = useState(false)

      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      const navigate = useNavigate();

      useEffect(() => {
            recordVisit(location.pathname)
            setVideoElement()
      }, [myState])

      async function refresh() {
            await unlockHandler(true)
      }

      useEffect(() => {
            // setInit(true)
            //unlockHandler()
            // fetchVideo()
            const handleContextmenu = e => {
                  e.preventDefault()
            }
            document.addEventListener('contextmenu', handleContextmenu)
            return function cleanup() {
                  document.removeEventListener('contextmenu', handleContextmenu)
            }
      }, [])



      useEffect(() => {
            console.log('on start time:')
            console.log(new Date())
            setTimeout(() => {
                  unlockHandler(false)
                  console.log('on Timeout time:')
                  console.log(new Date())
            }, 1050);
      }, [])
      async function unlockHandler(doAlert) {
            var userAddress = ''
            let tronweb = window.tronWeb;
            if (!tronweb && doAlert) {
                  alert('Please open your wallet and try again.')
                  //setConfirmBtnDisabled(false)
            } else {
                  await window.tronLink.request({ method: 'tron_requestAccounts' })
                  console.log(tronweb)
                  console.log(tronweb.isRevoked)
                  if (!tronweb.defaultAddress.base58 && doAlert) {
                        alert('Please open your wallet and try again.')
                  } else {
                        try {
                              console.log(tronweb.defaultAddress.base58)
                              userAddress = tronweb.defaultAddress.base58
                              let gres = await getVideo(userAddress, videoTitle)
                              // let gres = await getVideo(userAddress, accessToken)
                              console.log('On LVideo - getVideo - gres:')
                              console.log(gres)
                              if (gres.status == 'sign') {
                                    console.log('user must sign the message')
                                    var signature = null;
                                    try {
                                          signature = await tronweb.trx.signMessageV2(gres.message) || '';
                                          console.log(signature)
                                          let data = await saveSignatureAndGetVideo(signature, gres.time, userAddress, accessToken)
                                          console.log('data after sign:')
                                          console.log(data)
                                          console.log(data)
                                          setVideo({ url: data.link })
                                          setVideoInit(true)
                                          setInit(true)
                                          setVideoElement(<PlayerWindow url={data.link} />)
                                    } catch (error) {
                                          console.log(signature)
                                          if (doAlert) {
                                                alert(error.message ? error.message : error)
                                          }
                                    }
                              } else {
                                    console.log('user has access to data')
                                    console.log(gres.link)
                                    setVideo({ url: gres.link })
                                    setVideoInit(true)
                                    setInit(true)
                                    setVideoElement(<PlayerWindow url={gres.link} />)
                              }

                        } catch (error) {
                              if (doAlert) {
                                    alert(error.message ? error.message : error)
                              }
                              //setConfirmBtnDisabled(false)
                        }
                  }
            }
      }


      return (
            <>
                  {
                        init ?
                              <>
                                    {videoElement}
                              </> :
                              <MainLayout>
                                    <div className="video-loading">
                                          <div className="video-loading-shape-frame">
                                                <div className="video-loading-shape-box">
                                                      <img src={mv2} alt="movie icon" className="vid-img" />
                                                      {/* <div className="video-loading-circle"> */}
                                                      <img src={loadingIcon} alt="movie icon" className="vid-img video-loading-circle" />
                                                      {/* </div> */}
                                                </div>
                                          </div>
                                          <div className="video-loading-shape-text">
                                                <br />

                                                <h2>Have your wallet open and refresh to load the video.</h2>
                                                <br />
                                                <br />

                                                <button className="free-access-btn" onClick={refresh}>Refresh</button>
                                                <br />
                                                <br />

                                          </div>
                                    </div>
                              </MainLayout>


                  }
            </>
      )
}

export default VideoPlayLocked