import React, { useState } from 'react';
import CreatePrivateVideo from './createPrivateVideo';
import GetAllPrivateVideos from './getAllPrivateVideos';
import UpdatePrivateVideo from './updatePrivateVideo';
import DeletePrivateVideo from './deletePrivateVideo';

const PrivateVideoManagement = () => {
      const [view, setView] = useState('viewAll'); // Default view
      const [toEdit, setToEdit] = useState();
      const [toDelete, setToDelete] = useState();
      const [toDeleteVid, setToDeleteVid] = useState();
      const [toDeleteTitle, setToDeleteTitle] = useState();

      function selector(id) {
            console.log('Set selector to: ' + id)
            setToEdit(id)
            setView('update')
      }
      function deletor(id, title, vid) {
            console.log('Set deletor to: ' + id)
            setToDelete(id)
            setToDeleteVid(vid)
            setToDeleteTitle(title)
            setView('delete')
      }

      const renderComponent = () => {
            switch (view) {
                  case 'create':
                        return <CreatePrivateVideo />;
                  case 'viewAll':
                        return <GetAllPrivateVideos selector={selector} deletor={deletor} />;
                  case 'update':
                        return <UpdatePrivateVideo match={{ params: { id: toEdit } }} />;
                  case 'delete':
                        return <DeletePrivateVideo match={{ params: { id: toDelete, title: toDeleteTitle, vid: toDeleteVid } }} />;
                  default:
                        return <CreatePrivateVideo />;
            }
      };

      return (
            <div>
                  <h1>Private Video Management</h1>
                  <div style={{ marginBottom: '20px' }}>
                        <button onClick={() => setView('create')}>Create Private Video</button>
                        <button onClick={() => setView('viewAll')}>View All Private Videos</button>
                        <button onClick={() => setView('update')}>Update Private Video</button>
                        <button onClick={() => setView('delete')}>Delete Private Video</button>
                  </div>
                  <div>{renderComponent()}</div>
            </div>
      );
};

export default PrivateVideoManagement;
