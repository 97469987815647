function Preview({ courseAP, isFree, accessHandler }) {
      return (
            <>
                  {/* <div className="padding-bottom-0">
                        <p>Need to buy the Course for full access. </p>
                  </div>
                  <br /> */}
                  <div className="get-course-btn">
                        <button className="free-access-btn" onClick={() => accessHandler()}>See Preview Videos</button>
                  </div>
            </>
      )
}


export default Preview