import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const DeletePaidCourse = ({ match  }) => {
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      const handleDelete = async () => {
            try {
                  const response = await fetch(apiUrl + `paidCourse/deleteCourse/${match.params.id}`, {
                        method: 'DELETE',
                        headers: {
                              'x-auth-token': localStorage.getItem('token')
                        }
                  });
                  const data = await response.json();
                  console.log('Delete paid course > handleDelete > response:', data);
                  setSuccess('Course deleted successfully');
                  setError('');
            } catch (err) {
                  console.log(err);
                  setError('Error deleting course');
                  setSuccess('');
            }
      };

      return (
            <div>
                  <h2>Delete Paid Course</h2>
                  <h3>Are you sure to delete the course?</h3>
                  <h4>Id: {match.params.vid}</h4>
                  <h4>title: {match.params.title}</h4>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
                  <button onClick={handleDelete}>Delete Course</button>
            </div>
      );
};

export default DeletePaidCourse;
