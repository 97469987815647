import * as Data from "@as/js/data"
import { useEffect, useState, useImperativeHandle } from "react";
import Loading from "../../../../loading";
import { Link } from "react-router-dom";
import videoIcon from "@as/image/Icons/video-icon.svg"
// import lockedIcon from "@as/image/Icons/locked.svg"
// import unlockedIcon from "@as/image/Icons/unlocked.svg"
import zipIcon from "@as/image/Icons/zip-icon.svg"
import arrowTo from "@as/image/Icons/arrow-to-right.svg"
import dash from "@as/image/Icons/dash.svg"

function VideoLinkBox({ course, ids, assetIds, courseTitle, blinker, isUnlocked, doUnlockHandler, lockDisplayIcon }) {

      const [videos, setVideos] = useState([])
      const [assets, setAssets] = useState([])
      const [showUnlock, setShowUnlock] = useState(false)
      // const [lockIcon, setLockIcon] = useState(lockedIcon)










      useEffect(() => {
            const checkShowUnlockButton = () => {
                  console.log(course.isAccessibleForFree)
                  console.log(isUnlocked)
                  if (!course.isAccessibleForFree && !isUnlocked) {
                        setShowUnlock(true)
                  }
                  if (isUnlocked) {
                        setShowUnlock(false)
                        //setLockIcon(unlockedIcon)
                  }
            }
            checkShowUnlockButton()
      })

      async function fetchVideos() {
            console.log('assetIds to search: ')
            console.info(assetIds)
            console.info('courseTitle: ' + courseTitle)
            var courseVideos = await Data.getVideosFor(ids);
            if (assetIds[0] != '') {
                  var courseAssets = await Data.getAssetsFor(assetIds);
                  setAssets(courseAssets)

            }

            console.info(courseVideos)
            console.info(courseAssets)

            setVideos(courseVideos)

            setTimeout(() => {
                  console.info(videos)
            }, 1000);
      }

      useEffect(() => {
            fetchVideos()
      }, [])

      const buyBlink = () => {
            console.log('buyBlink = ()')
            setTimeout(() => {
                  document.getElementById('type-section')?.scrollIntoView({ behavior: 'smooth' })
                  setTimeout(() => {
                        blinker('payments-box-2')
                        setTimeout(() => {
                              blinker('payments-box')
                              setTimeout(() => {
                                    blinker('payments-box-2')
                                    setTimeout(() => {
                                          blinker('payments-box')
                                          setTimeout(() => {
                                                blinker('payments-box-2')
                                                setTimeout(() => {
                                                      blinker('payments-box')
                                                      setTimeout(() => {
                                                            blinker('payments-box-2')
                                                            setTimeout(() => {
                                                                  blinker('payments-box')
                                                            }, 100)
                                                      }, 200)
                                                }, 100)
                                          }, 200)
                                    }, 100)
                              }, 200)
                        }, 100)
                  }, 400)
            }, 100)
      }

      function getAccessTokenFor(title, type) {
            if (type === 'video') return 'videoAccessTokenFor_' + title.replace(/ /g, '_')
            if (type === 'asset') return 'assetAccessTokenFor_' + title.replace(/ /g, '_')
      }

      return (<>
            <br />



            <div className="course-info">
                  {
                        videos ?
                              <>
                                    {videos.map((v, i) => (
                                          <p key={i}>
                                                {v.isLocked ?
                                                      <span>
                                                            <img src={videoIcon} alt="Video Play Icon" width={'24'} />&nbsp;
                                                            <img src={dash} className="dash" alt="Video Play Icon" width={'14'} />
                                                            <img src={lockDisplayIcon} alt="Video Play Icon" width={'24'} />
                                                      </span> :
                                                      <img src={videoIcon} alt="Video Play Icon" width={'24'} />
                                                }
                                                &nbsp;
                                                Part {i + 1} :
                                                &nbsp;
                                                {v.isLocked ?
                                                      <span className="card-foot-Readmore-link" >

                                                            {isUnlocked ?
                                                                  <Link className="card-foot-Readmore-link" target="_blank" to={{
                                                                        pathname: `/course/${courseTitle}/privatevideo/${v.title.replace(/ /g, '_')}/${getAccessTokenFor(v.title, 'video')}`
                                                                  }}  >
                                                                        {v.title}
                                                                  </Link>
                                                                  : <span> {v.title} - <span className="go-to-buy-link" onClick={buyBlink}> Buy the course</span></span>
                                                            }
                                                      </span>

                                                      :
                                                      <Link className="card-foot-Readmore-link" target="_blank" to={{
                                                            pathname: `/course/${courseTitle}/videos/${v.title.replace(/ /g, '_')}`
                                                      }}  >
                                                            {v.title}
                                                      </Link>
                                                }
                                          </p>


                                    ))}
                              </>

                              :
                              <Loading />
                  }
                  {
                        assets ?
                              <>
                                    {assets.map((v, i) => (
                                          <p key={i}>
                                                {v.isLocked ?
                                                      <span>
                                                            <img src={zipIcon} alt="Asset download Icon" width={'24'} />&nbsp;
                                                            <img src={dash} className="dash" alt="Video Play Icon" width={'14'} />
                                                            <img src={lockDisplayIcon} alt="Video Play Icon" width={'24'} />
                                                      </span>
                                                      :
                                                      <img src={zipIcon} alt="Asset download Icon" width={'24'} />
                                                }

                                                &nbsp;
                                                FIle {i + 1} :
                                                &nbsp;

                                                {v.isLocked ?
                                                      <span className="card-foot-Readmore-link" >

                                                            {isUnlocked ?

                                                                  <Link className="card-foot-Readmore-link" target="_blank" to={{
                                                                        pathname: `/course/${courseTitle}/videos/${v.title.replace(/ /g, '_')}/${getAccessTokenFor(v.title, 'asset')}`
                                                                  }}  >
                                                                        {v.title}
                                                                  </Link>
                                                                  : <span>{v.title} - <span className="go-to-buy-link" onClick={buyBlink}> Buy the course</span></span>}
                                                      </span>
                                                      :
                                                      // <Link className="card-foot-Readmore-link" target="_blank" to={{
                                                      //       pathname: `/course/${courseTitle}/videos/${v.title.replace(/ /g, '_')}`
                                                      // }}  >
                                                      //       {v.title}
                                                      // </Link>
                                                      <a href={v.url} className="card-foot-Readmore-link">{v.title}</a>
                                                }
                                          </p>
                                    ))}
                              </> : <></>
                  }


                  {
                        showUnlock ?
                              <p className="mobile-center">
                                    <br />
                                    To Unlock all parts,  <button onClick={buyBlink} className="free-access-btn">Buy the Course</button>
                                    <br />
                                    <br className="only-on-mobile" />
                                    {/* <span onClick={() => { doUnlockGetAlert() }}>go </span> */}
                                    Bougth it before? <button
                                          onClick={doUnlockHandler}  // this is working
                                          // onClick={() => { t2() }} // this is for test
                                          className="free-access-btn">Unlock the Course</button>
                              </p>
                              : <></>
                  }
            </div>
      </>)
}

export default VideoLinkBox