import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const UpdatePaidCourse = ({ match }) => {
      const [course, setCourse] = useState({});
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      useEffect(() => {
            const fetchCourse = async () => {
                  try {
                        const response = await fetch(apiUrl + `paidCourse/getCourse/${match.params.id}`, {
                              method: 'GET',
                              headers: {
                                    'x-auth-token': localStorage.getItem('token')
                              }
                        });
                        const data = await response.json();
                        setCourse(data);
                  } catch (err) {
                        console.log(err);
                        setError('Error fetching course');
                  }
            };
            fetchCourse();
      }, [match.params.id]);

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  const response = await fetch(apiUrl + `paidCourse/updateCourse/${course._id}`, {
                        method: 'PUT',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(course)
                  });
                  console.log('Update paid course > handleSubmit > response:', await response.json());
                  setSuccess('Course updated successfully');
                  setError('');
            } catch (err) {
                  console.log(err);
                  setError('Error updating course');
                  setSuccess('');
            }
      };

      return (
            <div>
                  <h2>Update Paid Course</h2>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
                  <form onSubmit={handleSubmit} className="admin-form">
                        <div>
                              <label>Course ID</label>
                              <input
                                    type="text"
                                    value={course.courseId || ''}
                                    onChange={(e) => setCourse({ ...course, courseId: e.target.value })}
                              />
                        </div>
                        <div>
                              <label>Course Title</label>
                              <input
                                    type="text"
                                    value={course.courseTitle || ''}
                                    onChange={(e) => setCourse({ ...course, courseTitle: e.target.value })}
                              />
                        </div>
                        <div>
                              <label>Private Video IDs (comma-separated)</label>
                              <input
                                    type="text"
                                    value={course.coursePrivateVideoIds || ''}
                                    onChange={(e) => setCourse({ ...course, coursePrivateVideoIds: e.target.value.split(',') })}
                              />
                        </div>
                        <div>
                              <label>Private Asset IDs (comma-separated)</label>
                              <input
                                    type="text"
                                    value={course.coursePrivateAssetIds || ''}
                                    onChange={(e) => setCourse({ ...course, coursePrivateAssetIds: e.target.value.split(',') })}
                              />
                        </div>
                        <button type="submit">Update Course</button>
                  </form>
            </div>
      );
};

export default UpdatePaidCourse;
