import MainLayout from "../template/MainLayout"
import { useLocation } from "react-router-dom";
import { recordVisit, sendQWM } from "../../../asset/js/site-cookies"
import { useEffect, useState } from "react";
import SendResultDisplay from '../agent/agentSubs/subComponents/sendResultDisplay'
import { type } from "@testing-library/user-event/dist/type";
// import SendResultDisplay from '../agent/agentSubs/subComponents/sendResultDisplay'
import { Helmet } from "react-helmet";
function QueryWall() {

      const [myState, setMyState] = useState('initialState')
      const [userQueryMessagePH1, setUserQueryMessagePH1] = useState('Hi.\n');
      const [sendMessageState, setSendMessageState] = useState({});
      const [sendDisabled, setSendDisabled] = useState(false)


var content = {
      url: 'https://melarix.com/querywall',
      title: 'QueryWall on Melarix - Exclusive Q&A Hub for Students',
      description: 'QueryWall is a question board for students to ask questions, get expert answers, and engage with the learning community. Read-only access available for visitors.',
      type: 'website',
      image: 'https://melarix.com/melarix-mascot.png'
}


      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])


      // Handle Message phase 1
      const sendMessage = async () => {
            setSendDisabled(true)
            console.log(userQueryMessagePH1.length)




            try {

                  const res = await sendQWM(userQueryMessagePH1)
                  console.log(res)
                  console.log(res.status)
                  console.log(typeof (res))
                  if (res.status == 'error') {
                        return setSendMessageState({ msg: res.msg, isSuccess: false });
                  }
                 
                 
                  if (res.status == 'success') {
                        console.log('res is ok')
                        setSendMessageState({ msg: 'Message has been sent successfully', isSuccess: true });
                  }  
            } catch (error) {
                  console.log('Server error while sending message')
                  setSendMessageState({ msg: 'Unable to send the message. May try later.', isSuccess: false });
                  console.log(error)

                  // if (data.status == 'error') {
                  //       setSendMessageState({ msg: data.msg, isSuccess: true });
                  // }

            }
      };




      return (
            <MainLayout>
 <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />

                        <link rel="canonical" href={content.url.toString()} />

                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>
                  <>
                        <div className="page-body">
                              <div className="page-head">
                                    <h1>QueryWall</h1>
                                    {/* <h1>🚀 Introducing QueryWall: Your Learning Support Hub!</h1> */}
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>What is QueryWall?</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      QueryWall is an exclusive question board designed for students who purchase our paid courses. It's where you can ask questions, get expert answers, and connect with fellow learners to overcome obstacles easily.
                                                </p>

                                          </div>
                                    </div>
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>How Will It Work?</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      <b>Ask Questions:</b>    Paid students can post their questions directly on QueryWall.
                                                </p><p>
                                                      <b>Get Expert Answers:</b>   Instructors and community members will provide clear, in-depth responses.
                                                </p><p>
                                                      <b>Stay Informed:</b>    Important discussions and follow-up insights will be shared here.
                                                </p><p>
                                                      <b>Public Read-Only Access:</b> Even if you haven't purchased a course, you can still read the questions and answers.
                                                </p>

                                          </div>
                                    </div>

                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>Coming March 2025!
                                                </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      We're working hard to bring QueryWall to life. The full version will be launching in March! Stay tuned for updates.                                                </p>

                                          </div>
                                    </div>

                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>Have Questions or Suggestions?
                                                </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Meanwhile, you can send a message directly to the admin using the form below or
                                                      head to <a target="_blank" href="https://discord.gg/4ARrr7WJPC">Discord</a> and
                                                      chat with instructors or other students. <br />We'd love to hear your thoughts!
                                                </p>

                                          </div>
                                    </div>



                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>📧 Send a message
                                                </h2>
                                          </div>
                                          <div className="part-body">
                                                <h3> <i className='txt-sm'>(Max 400)</i></h3>
                                                <div className='bio-btn-frame-input'>

                                                      <textarea className="querywall-message-textarea"
                                                            value={userQueryMessagePH1}
                                                            onChange={(e) => {
                                                                  setUserQueryMessagePH1(e.target.value)
                                                            }}
                                                            rows="6"
                                                            maxLength={400}
                                                            disabled={sendDisabled}
                                                      />
                                                </div>
                                                <div className='bio-btn-frame-btn'>

                                                      <div className='bio-btn-frame'>
                                                            <button onClick={sendMessage} className='send-message-btn' disabled={sendDisabled}>Send</button>

                                                      </div>
                                                      <br />
                                                      <SendResultDisplay obj={sendMessageState} />
                                                </div>
                                                <p> <i className='txt-sm'>P.S. Include your email if you'd like us to get in touch.

                                                </i></p>


                                          </div>
                                    </div>
                              </div>
                        </div>

                  </>
            </MainLayout >
      )
}

export default QueryWall