import MainLayout from "../template/MainLayout"
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function TermsOfUse() {
      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])

      var content = {
            url: 'https://melarix.com/terms_of_use',
            title: 'Terms of Use - Melarix Platform Rules and Regulations',
            description: 'Read the Melarix Terms of Use covering user conduct, intellectual property, and privacy. By using our platform, you agree to these terms.',
            type: 'website',
            image: 'https://melarix.com/melarix-mascot.png'

      }


      return (
            <MainLayout>
                  <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />

                        {/* <link rel="canonical" href={content.url.toString()} /> */}

                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>
                  <>
                        <div className="page-body">
                              <div className="page-head">
                                    <h1>Terms of use</h1>
                              </div>



                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>1. Acceptance of Terms </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      1.1. By accessing and using the Melarix platform, you agree to abide by these Terms of Use and all applicable laws and regulations. If you do not agree with these terms, please do not use the platform.
                                                </p><p>
                                                      1.2. Melarix may update these Terms of Use from time to time. Users are responsible for reviewing the Terms periodically, such as before each use of the platform, to stay informed of any changes. Subscribers to our email newsletter will receive notifications about significant updates to these Terms of Use. All users are encouraged to subscribe to stay informed.
                                                </p><p>
                                                      1.3. Continued use of the platform after any changes to these Terms constitutes your acceptance of the updated Terms.
                                                </p>
                                          </div>
                                    </div>
                              </div>

                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>2. User Conduct</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      2.1. You are responsible for any content you post or share on the platform.
                                                </p><p>
                                                      2.2. You agree not to engage in any illegal, harmful, or disruptive activities on the platform.
                                                </p><p>
                                                      2.3. Melarix reserves the right to remove or restrict access to content that violates these terms.
                                                </p><p>
                                                      2.4. We uniquely identify users solely by their wallet addresses. No other means of identification are collected or used. Your wallet address serves as the key to accessing our services, ensuring your privacy and anonymity. Users are responsible for securing their wallet and private keys. Melarix is not liable for any loss of access or funds resulting from compromised wallets.
                                                </p>

                                          </div>
                                    </div>
                              </div>

                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>3. Intellectual Property </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      3.1. All content on the platform is protected by copyright and other intellectual property laws.
                                                </p><p>
                                                      3.2. <b> Users are strictly prohibited from downloading, recording, or otherwise duplicating any video content available on the platform. All videos must be viewed exclusively through the platform's designated interface.</b>
                                                </p><p>
                                                      3.3. <b> Users are expressly forbidden from sharing, distributing, or otherwise making available any purchased assets to third parties. All content on the platform is safeguarded under applicable copyright and intellectual property laws.</b>

                                                </p>
                                          </div>
                                    </div>
                              </div>

                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>4. Privacy </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      4.1. Your use of the platform is subject to our Privacy Policy.
                                                </p><p>
                                                      4.2. By using the platform, you consent to the collection and use of your data as outlined in the Privacy Policy.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>5. Payment and Access Verification</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>

                                                      5.1. No third-party payments are allowed. To purchase and access any course or service on the platform, users must pay the required fee using their own cryptocurrency wallet.
                                                </p><p>
                                                      5.2. Access to purchased courses or services is contingent upon ongoing verification of ownership of the wallet used for payment. Users are required to confirm ownership by signing a verification request through their wallet whenever prompted by the platform.
                                                      Failure to verify ownership will result in the loss of access to the course or service until verification is successfully completed.
                                                </p><p>
                                                      5.3. Melarix reserves the right to modify or update the payment and verification process at any time, including but not limited to changes in the method of verification, frequency, or requirements. Users will be notified of any significant changes as outlined in these Terms of Use.

                                                </p><p>
                                                      5.4. Be cautious of your payments. We provide you with all the information you need before making a purchase, such as a preview of content. There will be no refunds under any circumstances.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>6. Disclaimer </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Melarix provides the platform 'as is' and makes no warranties regarding its accuracy, availability, or fitness for a particular purpose. We are not responsible for any direct, indirect, incidental, or consequential damages resulting from your use of the platform.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2>7. Termination </h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      Melarix reserves the right to terminate or suspend your access to the platform for violations of these terms or for any other reason.
                                                </p>
                                          </div>
                                    </div>
                              </div>
                              <div className="page-content">
                                    <div className="page-part">
                                          <div className="part-head">
                                                <h2> 8. Governing Law</h2>
                                          </div>
                                          <div className="part-body">
                                                <p>
                                                      In accordance with Web3 principles, we operate as a decentralized and self-governed platform, Any complaints or disputes will be handled by platform moderators, whose decisions will be final and binding.
                                                </p>
                                          </div>
                                    </div>
                              </div>





                        </div>
                  </>
            </MainLayout>
      )
}

export default TermsOfUse