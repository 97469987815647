import { useState, useEffect } from "react"
import MainLayout from "../template/MainLayout"
import { apiUrl } from '../../../asset/js/allStatics'

function AffiliateLanding() {

      const [filledSpots, setfilledSpots] = useState(0)


      useEffect(() => {
            // Replace with the actual API call to get the public agent data
            const fetchAgentData = async () => {
                  try {

                        const res = await fetch(apiUrl + 'agent/filled');
                        const data = await res.json();
                        if (res.ok) {
                              setfilledSpots(data.filled);
                              console.log(data)
                        } else {
                        }
                  } catch (error) {
                        console.log(error)
                  }
            };

            fetchAgentData();

      }, []);

      return (


            <MainLayout>



                  <div className="my-body">

                        <div className="my-header">
                              <div className="header-content">
                                    <h1>Join the Melarix Affiliate Program</h1>
                                    <p>Earn rewards by promoting blockchain and web3 courses to your audience.</p>
                              </div>
                              <br />
                              <br />
                              <div className="header-content-comment">
                                    <p>
                                          The Melarix Affiliate Program is not live yet and will launch in March 2025.
                                          <br />
                                          For any inquiries or partnership opportunities, please contact <a href="mailto:matthewshelb@gmail.com" className="foot-link">MatthewShelB@gmail.com</a>
                                    </p>
                              </div>
                        </div>

                        <div className="my-section how-it-works">
                              <h2>How It Works</h2>
                              <div className="steps">
                                    <div className="step">
                                          <h3>Step 1: Sign Up</h3>
                                          <p>Join the Melarix Affiliate Program by signing up using our simple form. No experience required.</p>
                                    </div>
                                    <div className="step">
                                          <h3>Step 2: Share Your Code</h3>
                                          <p>Receive a unique referral code to share with your audience. Your audience gets 20% off!</p>
                                    </div>
                                    <div className="step">
                                          <h3>Step 3: Earn Commissions</h3>
                                          <p>Earn 30% commission for every purchase made using your referral code, instantly transferred to your crypto wallet.</p>
                                    </div>
                              </div>
                        </div>

                        <div className="my-section why-join">
                              <h2>Why Join the Melarix Affiliate Program?</h2>
                              <div className="benefits">
                                    <div className="benefit">
                                          <h3>High Commissions</h3>
                                          <p>Earn 30% for every sale made through your referral link. That's one of the highest rates in the industry.</p>
                                    </div>
                                    <div className="benefit">
                                          <h3>Easy to Join</h3>
                                          <p>No prerequisites or special skills are required. Just sign up, share your code, and start earning.</p>
                                    </div>
                                    <div className="benefit">
                                          <h3>Instant Payouts</h3>
                                          <p>Receive your commission instantly, directly to your crypto wallet once the order is paid.</p>
                                    </div>
                              </div>
                        </div>

                        <div className="my-section join-now" >
                              <h2>Ready to Join?</h2>
                              <span>
                                    <p>
                                          Become a part of Melarix's growth and help others explore blockchain and web3 development, while earning high rewards.
                                    </p>
                              </span>
                              <br />
                              <a href="/agent/register" className="cta-button">Join Now</a>
                        </div>
                  </div>

                  <br /><br />
                  <div className=" my-body-2 my-section why-join">
                        <h2>Limited Availability - Only <span className="marked-red">20</span> Spots!</h2>
                        <div className="benefits">
                              <div className="benefit inside-shadow">
                                    <h3>High Commissions</h3>
                                    <p>We're currently accepting <span className="marked-lawn">20</span> agents into the Melarix Affiliate Program. Already, <span className="marked-lawn">{filledSpots}</span> spots have been filled, and only <span className="marked-red">{20 - filledSpots}</span> spots are left! Act fast before all positions are taken.</p>
                              </div>

                              <div className="benefit inside-shadow">
                                    <h3>Instant Payouts</h3>
                                    <p>Once these spots are filled, registration will close until further notice. Don't miss out on this exclusive opportunity to earn 30% commission, paid instantly in crypto.</p>
                              </div>
                        </div>
                  </div>

                  <br /><br />

                  <div className=" my-body-2 my-section why-join">
                        <h2>Why You Should Act Now!</h2>
                        <div className="benefits">
                              <div className="benefit ">
                                    <h3>Exclusive Access</h3>
                                    <p>Be one of the first 100 agents and gain access to our high-demand courses, securing your position before others.</p>
                              </div>

                              <div className="benefit ">
                                    <h3>High-Earning Potential</h3>
                                    <p>Early agents will have an advantage in promoting to an untapped market, leading to higher commissions.</p>
                              </div>

                              <div className="benefit ">
                                    <h3>Instant Approval</h3>
                                    <p>Signing up now guarantees instant approval, as long as spots remain.</p>
                              </div>
                        </div>

                        <div className="my-section join-now" >
                              <h2>Ready to Join?</h2>
                              <span>
                                    <p>
                                          Become a part of Melarix's growth and help others explore blockchain and web3 development, while earning high rewards.
                                    </p>
                              </span>
                              <br />
                              <a href="/agent/register" className="cta-button">Join Now</a>
                        </div>
                  </div>
                  <div className="my-footer">
                        <p>Learn about affiliate program terms and conditions <a href="">here</a>.</p>
                  </div>




            </MainLayout>

      )


}

export default AffiliateLanding