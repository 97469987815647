import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const CreatePrivateVideo = () => {
      const [videoTitle, setVideoTitle] = useState('');
      const [videoId, setVideoId] = useState('');
      const [directoryPath, setDirectoryPath] = useState('');
      const [course, setCourse] = useState('');
      const [error, setError] = useState('');
      const [success, setSuccess] = useState('');

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  const newVideo = { videoTitle, videoId, directoryPath, course };
                  const response = await fetch(apiUrl + 'privateVideo/createVideo', { 
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(newVideo)
                  });
                  const data = await response.json();
                  console.log(data);
                  setSuccess('Check log for result');
                  setError('');
            } catch (err) {
                  console.log(err);
                  setError('Error creating video');
                  setSuccess('');
            }
      };

      return (
            <div>
                  <h2>Create Private Video</h2>
                  <form onSubmit={handleSubmit} className='admin-form'>
                        <div>
                              <label>Video ID</label>
                              <input
                                    type="text"
                                    value={videoId}
                                    onChange={(e) => setVideoId(e.target.value)}
                                    required
                              />
                        </div>
                        <div>
                              <label>Video Title</label>
                              <input
                                    type="text"
                                    value={videoTitle}
                                    onChange={(e) => setVideoTitle(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Directory Path</label>
                              <input
                                    type="text"
                                    value={directoryPath}
                                    onChange={(e) => setDirectoryPath(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Course</label>
                              <input
                                    type="text"
                                    value={course}
                                    onChange={(e) => setCourse(e.target.value)}
                              />
                        </div>
                        <button type="submit">Create Video</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}
            </div>
      );
};

export default CreatePrivateVideo;
