import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics';

const GetAllCourses = ({ selector, deletor }) => {
      const [courses, setCourses] = useState([]);
      const [error, setError] = useState('');

      useEffect(() => {
            const fetchCourses = async () => {
                  try {
                        const response = await fetch(apiUrl + 'paidCourse/getAllCourses', {
                              method: 'GET',
                              headers: {
                                    'x-auth-token': localStorage.getItem('token')
                              }
                        });
                        const data = await response.json();
                        console.log(data);
                        setCourses(data);
                  } catch (err) {
                        console.log(err);
                        setError('Error fetching courses');
                  }
            };
            fetchCourses();
      }, []);

      return (
            <div>
                  <h2>All Paid Courses</h2>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <table className='admin-table'>
                        <thead>
                              <tr>
                                    <th>Id</th>
                                    <th>Title</th>
                                    <th>Videos</th>
                                    <th>Assets</th>
                                    <th>Action</th>
                              </tr>
                        </thead>
                        <tbody>
                              {courses.map(course => (
                                    <tr key={course._id}>
                                          <td> {course.courseId}</td>
                                          <td> {course.courseTitle}</td>
                                          <td>{course.coursePrivateVideoIds ? course.coursePrivateVideoIds.join(',') : ''}</td>
                                          <td>{course.coursePrivateAssetIds ? course.coursePrivateAssetIds.join(',') : ''}</td>

                                          <td>
                                                <button onClick={() => { selector(course._id) }}>Update</button>
                                                &nbsp;|&nbsp;
                                                <button onClick={() => { deletor(course._id, course.courseTitle, course.courseId) }}>Delete</button>
                                          </td>

                                    </tr>
                              ))}
                        </tbody>
                  </table>


            </div>
      );
};

export default GetAllCourses;
