import MainLayout from "../template/MainLayout";
import { useLocation } from "react-router-dom";
import { recordVisit } from "../../../asset/js/site-cookies"
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";


const AgentTerms = () => {
      const [myState, setMyState] = useState('initialState')
      const location = useLocation();
      useEffect(() => {
            recordVisit(location.pathname)
      }, [myState])

      var content = {
            url: 'https://melarix.com/affiliate/terms',
            title: 'Melarix Affiliate Program Terms and conditions',
            description: 'Read the Melarix Affiliate Program Terms of Use covering user conduct, intellectual property, and privacy. By using our platform, you agree to these terms.',
            type: 'website',
            image: 'https://melarix.com/melarix-mascot.png'

      }

      return (
            <MainLayout>
                  <Helmet>

                        <title>{content.title}</title>
                        <meta property="title" content={content.title.toString()} />
                        <meta property="description" content={content.description.toString()} />

                        <link rel="canonical" href={content.url.toString()} />

                        <meta property="og:url" content={content.url.toString()} />
                        <meta property="og:type" content={content.type.toString()} />
                        <meta property="og:title" content={content.title.toString()} />
                        <meta property="og:description" content={content.description.toString()} />
                        <meta property="og:image" content={content.image.toString()} />
                  </Helmet>


                  <div className="page-body">



                        <div className="page-head">
                              <h1>Melarix Affiliate Program </h1>
                              <h2> Terms and Conditions </h2>
                              <p><b>Effective Date:</b> 2024 september 24
                              <br />
                              <b>Last Modified:</b> 2024 september 24</p>
                        </div>
                        <hr />

                        <div className="page-content">
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2> Welcome to the Melarix Affiliate Program. </h2>
                                    </div>
                                    <div className="part-body">
                                          <p>
                                                By participating in this program, you agree to abide by the following Terms and Conditions.
                                          </p>
                                          <p>
                                                Melarix reserves the right to modify these terms at any time, and your continued participation constitutes acceptance of any changes.
                                          </p>
                                        
                                    </div>
                              </div>
                        </div>

                        <hr />

                        <div className="page-content">
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>1. General Information </h2>
                                    </div>
                                    <div className="part-body">
                                          <p>
                                                <b>Program Name: </b>The program shall be referred to as the Melarix Affiliate Program.
                                          </p>
                                          <p>
                                                <b>Equal Treatment: </b>All affiliates will be treated equally, without differentiation in tiers or levels.
                                          </p>
                                          <p>
                                                <b>Agent Limitations: </b>Melarix may stop accepting new agents at any point and may remove inactive agents. The program may then open up to accept new agents.
                                          </p>
                                    </div>
                              </div>
                        </div>



                        <hr />



                        <div className="page-content">
                              {/*  2. Commission Structure */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>2. Commission Structure</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Commission Rate: </b>Affiliates will earn a 30% commission for each sale made through their referral code.</p>
                                          <p><b>Discounts: </b>Customers using an affiliate's referral code will receive a 20% discount on purchases.</p>
                                          <p><b>Exceptions: </b>No commissions will be applied to refunds or invalid transactions.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  3. Eligibility and Registration */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>3. Eligibility and Registration</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Eligibility: </b>There are no specific restrictions based on age, country, or professional background.</p>
                                          <p><b>Registration: </b>Affiliates must complete the registration form. Email and wallet addresses cannot be changed after registration.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  4. Payment Details */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>4. Payment Details</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Payment Method: </b>All payments will be made in USDT via the Tron blockchain. Affiliates are responsible for entering a valid wallet address during registration.</p>
                                          <p><b>Payout Frequency: </b>Payments are made instantly upon the successful completion of a transaction.</p>
                                          <p><b>Minimum Payout: </b>There is no minimum payout threshold.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  5. Tracking and Reporting */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>5. Tracking and Reporting</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Tracking: </b>Sales made using an affiliate's referral code will be tracked and reflected in the affiliate's dashboard.</p>
                                          <p><b>Reporting: </b>Affiliates can monitor their sales and commissions through the dashboard provided by Melarix.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  6. Affiliate Obligations */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>6. Affiliate Obligations</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Promotion Rules: </b>Affiliates must act ethically and refrain from spamming or engaging in deceptive practices.</p>
                                          <p><b>Platform Guidelines: </b>Affiliates must abide by the rules of the platforms they use for promotion.</p>
                                          <p><b>Content Restrictions: </b>Affiliates must not promise unrealistic results. Affiliates must not  use other agents' content without permission. Violations may lead to termination.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  7. Termination */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>7. Termination</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Termination Causes: </b>Melarix may terminate an affiliate's participation for violations of program terms, platform rules, or inactivity.</p>
                                          <p><b>Commission Payments Upon Termination: </b>All outstanding commissions will be paid before termination.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  8. Liability and Disclaimers */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>8. Liability and Disclaimers</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Liability: </b>Melarix is responsible for facilitating the transaction of commissions to the affiliate's wallet. Affiliates are responsible for ensuring their payment details are correct.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  9. Modification of Terms */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>9. Modification of Terms</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Right to Modify: </b>Melarix reserves the right to modify these terms at any time. Affiliates will be notified of changes via email.</p>
                                    </div>
                              </div>

                              <hr />

                              {/*  10. Discounts and Commissions */}
                              <div className="page-part">
                                    <div className="part-head">
                                          <h2>10. Discounts and Commissions</h2>
                                    </div>
                                    <div className="part-body">
                                          <p><b>Seasonal Discounts: </b>Melarix may offer temporary discounts on courses. Commission payments will be calculated based on the final discounted sale price.</p>
                                    </div>
                              </div>
                              <hr />
                              <div className="page-part">
                                     
                                    <div className="part-body">
                                          <p> By joining the Melarix Affiliate Program, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. If you have any questions, please contact us at <a href="mailto:melarix.com@gmail.com">melarix.com@gmail.com</a>.</p>
                                    </div>
                              </div>
                        </div>


                  </div>

            </MainLayout>
      )
}

export default AgentTerms