import CourseHome from './component/pages/coursePage/courseHome';
import './asset/style/App.css';
import './asset/style/card.css';
import './asset/style/font.css';
import './asset/style/guide.css';
import './asset/style/headerfooter.css';
import './asset/style/index.css';
import './asset/style/style.css';
import './asset/style/course.css';
import './asset/style/article.css';
import './asset/style/page.css';
import './asset/style/responsive.css';
import './asset/style/agent.css';
import './asset/style/subPages/affiliateLanding.css';
import './asset/style/subPages/video.css';
import './asset/style/subPages/admin.css';
import './asset/style/payment.css';




// simple routing for version 2
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import About from './component/pages/sidePages/about';
import Course from './component/pages/coursePage/singleCoursePage/course';
import ArticleList from './component/pages/articlePage/Articles';
import Article from './component/pages/articlePage/Article';
import Video from './component/pages/video/videoPlay';
import LVideo from './component/pages/video/videoPlayLocked';
import LightPaper from './component/pages/sidePages/lightPaper';
import TermsOfUse from './component/pages/sidePages/termsOfUse';
import HowToPurchase from './component/pages/sidePages/howToPurchase';
import PrivacyPolicy from './component/pages/sidePages/privacyPolicy';
import QueryWall from './component/pages/sidePages/querywall';  
import ReportBug from './component/pages/sidePages/reportBug';  
// round two
import AgentDashboard from './component/pages/agent/agentSubs/agentDashboard';
import AgentPublicProfile from './component/pages/agent/agentSubs/agentPublicProfile';
import AgentRegister from './component/pages/agent/agentSubs/agentRegister'
import AgentVerifyEmail from './component/pages/agent/agentSubs/agentVerifyEmail'
import AgentLogin from './component/pages/agent/agentSubs/agentLogin'
import AgentForgotPassword from './component/pages/agent/agentSubs/agentForgotPassword'
import AgentResetPassword from './component/pages/agent/agentSubs/agentResetPassword'


 
// import Router from "./routing"


import AgentResendEmail from './component/pages/agent/agentSubs/agentResendEmail';
import AffiliateLanding from './component/pages/agent/affiliateLanding';
import AgentTerms from './component/pages/agent/affiliateTerms';
import VideoTest from './component/pages/coursePage/singleCoursePage/subComponent/videoTest';
import PaidCourseManagement from './component/pages/admin/paidCourse/PaidCourseManagement';
import PrivateVideoManagement from './component/pages/admin/privateVideo/privateVideoManagement ';
import PrivateAssetManagement from './component/pages/admin/privateAssett/privateAssetManagement ';
// import { useEffect } from "react"



function App() {
  //getGeneralCookie()
  return (
    <div className="App">
      <BrowserRouter  >
        <Routes>
          {/* Starting from homepage */}
          <Route path='/:ref' element={<CourseHome />} />
          <Route path='/videotest/:videoName' element={<VideoTest />} />


          {/* Agent */}
          <Route path='/affiliate/introduction/' element={<AffiliateLanding />} />
          <Route path='/agent/dashboard/' element={<AgentDashboard />} />
          <Route path='/agent/:code' element={<AgentPublicProfile />} />
          <Route path='/agent/register/' element={<AgentRegister />} />
          <Route path='/agent/verifyemail/:toekn' element={<AgentVerifyEmail />} />
          <Route path='/agent/login/:comment' element={<AgentLogin />} />
          <Route path='/agent/login/' element={<AgentLogin />} />
          <Route path='/agent/ForgotPassword' element={<AgentForgotPassword />} />
          <Route path='/agent/resetpassword/:token' element={<AgentResetPassword />} />
          <Route path='/agent/resend/' element={<AgentResendEmail />} />
          <Route path='/affiliate/terms/' element={<AgentTerms />} />


          {/* Courses */}
          <Route path='/course/:id' element={<Course />} />
          <Route path='/video/:id' element={<Video />} />
          {/* <Route path='/video/:course/:id' element={<Video />} /> */}
          <Route path='/course/:courseTitle/videos/:videoTitle' element={<Video />} />
          <Route path='/course/:courseTitle/privatevideo/:videoTitle/:accessToken' element={<LVideo />} />


          {/* Articles */}
          <Route exact path="/article/:id" element={<Article />} />
          <Route exact path="/article/:id" element={<Article />} />
          <Route path='/articles' element={<ArticleList />} />
          <Route path='/article' element={<ArticleList />} />


          {/* Side pages */}
          <Route path='/about' element={<About />} />
          <Route path='/querywall' element={<QueryWall />} />
          <Route path='/reportbug' element={<ReportBug />} />
          <Route path='/lightpaper' element={<LightPaper />} />
          <Route path='/terms_of_use' element={<TermsOfUse />} />
          <Route path='/how_to_purchase' element={<HowToPurchase />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />



          <Route path='/admin/course' element={<PaidCourseManagement />} />
          <Route path='/admin/video' element={<PrivateVideoManagement />} />
          <Route path='/admin/asset' element={<PrivateAssetManagement />} />


          {/* Affiliate pages */}
          {/* // go to affiliate home */}
          {/* <Route path='/affiliate' element={<About />} /> */}



          {/* Not found - 404 */}
          <Route path='*' element={<CourseHome />} />


        </Routes>
      </BrowserRouter  >

    </div>
  );
}




// function App2() {
//   getGeneralCookie()


//   return (
//     <>
//       <RouterProvider router={Router} />
//     </>

//   );
// }

// function App3() {
//   return (
//     <div className="App">
//       <Router />
//     </div>)
// }
export default App;
