import { useEffect, useState } from "react"
import { apiUrl } from '../../../../../asset/js/allStatics'

const AdminPanel = () => {


      const [allCourses, setAllCourses] = useState(null)
      const [courseId, setCourseId] = useState('');
      const [courseTitle, setCourseTitle] = useState('');
      const [coursePrivateVideoIds, setCoursePrivateVideoIds] = useState('');
      const [coursePrivateAssetIds, setCoursePrivateAssetIds] = useState('');


      //VIdeo
      const [courseIdVideo, setCourseIdVideo] = useState('');
      const [videoTitleVideo, setvideoTitleVideo] = useState('');
      const [videoIdVideo, setVideoIdVideo] = useState('');
      const [directoryPathVideo, setDirectoryPathVideo] = useState('');

      //Asset
      const [courseIdAsset, setCourseIdAsset] = useState('');
      const [assetTitleAsset, setAssetTitleAsset] = useState('');
      const [assetIdAsset, setAssetIdAsset] = useState('');
      const [directoryPathAsset, setDirectoryPathAsset] = useState('');

      async function reefreshAllCourses() {
            let token = localStorage.getItem('token');
            console.log(token)
            const res = await fetch(apiUrl + 'admin/getAllCourses', {
                  method: 'GET',
                  headers: { 'x-auth-token': token },
            });
            console.log(res)
            let result = await res.json()
            console.log(result)
            setAllCourses(result.data || '')
      }
      useEffect(() => {


            async function getAllCourses() {
                  reefreshAllCourses()
            }
            getAllCourses()
      }, [])

      const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                  let body = {
                        courseId:selectedCourse.courseId,
                        courseTitle: selectedCourse.courseTitle,
                        coursePrivateVideoIds: selectedCourse.coursePrivateVideoIds.split(','), // Convert string to array
                        coursePrivateAssetIds: selectedCourse.coursePrivateAssetIds.split(','), // Convert string to array
                  }
                  console.log(JSON.stringify(body))
                  const response = await fetch(apiUrl + 'admin/updateCourse', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(body)
                  }

                  );
                  console.log('Course updated:', await response.json());
            } catch (error) {
                  console.error('Error updating course:', error);
            }
      }
      const handleSubmitVideo = async (e) => {
            e.preventDefault();
            try {
                  let body = {
                        videoId: videoIdVideo,
                        videoTitle: videoTitleVideo,
                        courseId: courseIdVideo,
                        directoryPath: directoryPathVideo
                  }

                  console.log(JSON.stringify(body))
                  const response = await fetch(apiUrl + 'admin/updatePrivateVideo', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(body)
                  }

                  );
                  console.log('Video updated:', await response.json());
            } catch (error) {
                  console.error('Error updating course:', error);
            }
      }
      const handleSubmitAsset = async (e) => {
            e.preventDefault();
            try {
                  let body = {
                        assetIdAsset,
                        assetTitleAsset,
                        courseIdAsset,
                        directoryPathAsset
                  }
                  console.log(JSON.stringify(body))
                  const response = await fetch(apiUrl + 'admin/updateCourse', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                              'x-auth-token': localStorage.getItem('token')
                        },
                        body: JSON.stringify(body)
                  }

                  );
                  console.log('Course updated:', await response.json());
            } catch (error) {
                  console.error('Error updating course:', error);
            }
      }
      const [selectedCourse, setSelectedCourse] = useState({
            courseId: '',
            courseTitle: '',
            coursePrivateVideoIds: '',
            coursePrivateAssetIds: ''
      });
      const editCourse = (course) => {
            setSelectedCourse({
                  courseId: course.courseId,
                  courseTitle: course.courseTitle,
                  coursePrivateVideoIds: course.coursePrivateVideoIds.join(','), // Convert array to comma-separated string
                  coursePrivateAssetIds: course.coursePrivateAssetIds.join(',')  // Convert array to comma-separated string
            });
      }
      return (<>
            <h2>Admin Panel</h2>

            <br />
            <label htmlFor="AllCourses:"></label>

            <div>
                  <h4>Course:</h4>
                  <table>
                        <thead>
                              <tr>
                                    <th>Id</th>
                                    <th>Titile</th>
                                    <th>Videos</th>
                                    <th>Assets</th>
                                    <th>Action</th>
                              </tr>
                        </thead>
                        <tbody>
                              {allCourses ? allCourses.map((course, index) => (
                                    <tr id={'row-' + index} key={'row-' + index}>
                                          <td>{course.courseId}</td>
                                          <td>{course.courseTitle}</td>
                                          <td>{course.coursePrivateVideoIds.map((V, I) => (<span key={I}>{V},</span>))}</td>
                                          <td>{course.coursePrivateAssetIds.map((A, I) => (<span key={I}>{A},</span>))}</td>
                                          <td><button onClick={() => { editCourse(course) }}>Edit</button></td>
                                    </tr>
                              )) : <></>}
                        </tbody>
                  </table>

                  <form onSubmit={handleSubmit} className="admin-form">
                        <div>
                              <label>Course ID (Required):</label>
                              <input
                                    type="text"
                                    value={selectedCourse.courseId}
                                    onChange={(e) => setSelectedCourse({ ...selectedCourse, courseId: e.target.value })}
                                    required
                              />
                        </div>

                        <div>
                              <label>Course Title:</label>
                              <input
                                    type="text"
                                    value={selectedCourse.courseTitle}
                                    onChange={(e) => setSelectedCourse({ ...selectedCourse, courseTitle: e.target.value })}
                              />
                        </div>

                        <div>
                              <label>Private Video IDs (comma-separated):</label>
                              <input
                                    type="text"
                                    value={selectedCourse.coursePrivateVideoIds}
                                    onChange={(e) => setSelectedCourse({ ...selectedCourse, coursePrivateVideoIds: e.target.value })}
                              />
                        </div>

                        <div>
                              <label>Private Asset IDs (comma-separated):</label>
                              <input
                                    type="text"
                                    value={selectedCourse.coursePrivateAssetIds}
                                    onChange={(e) => setSelectedCourse({ ...selectedCourse, coursePrivateAssetIds: e.target.value })}
                              />
                        </div>
                        <div className="form-btns">
                              <button type="submit" className="btn-primary">Update Course</button>
                              <button onClick={reefreshAllCourses} className="btn-primary">Refress All Courses</button>
                        </div>
                  </form>

                  <br /><br />
                  <h4>Video:</h4>
                  <form onSubmit={handleSubmitVideo} className="admin-form">
                        <div>
                              <label>Video ID (Required):</label>
                              <input
                                    type="text"
                                    value={videoIdVideo}
                                    onChange={(e) => setVideoIdVideo(e.target.value)}
                                    required
                              />
                        </div>

                        <div>
                              <label>Video Title:</label>
                              <input
                                    type="text"
                                    value={videoTitleVideo}
                                    onChange={(e) => setvideoTitleVideo(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Video directoryPath:</label>
                              <input
                                    type="text"
                                    value={directoryPathVideo}
                                    onChange={(e) => setDirectoryPathVideo(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Video course:</label>
                              <input
                                    type="text"
                                    value={courseIdVideo}
                                    onChange={(e) => setCourseIdVideo(e.target.value)}
                              />
                        </div>


                        <div className="form-btns">
                              <button type="submit" className="btn-primary">Update Video</button>
                        </div>
                  </form>



                  <br /><br />
                  <h4>Asset:</h4>
                  <form onSubmit={handleSubmitAsset} className="admin-form">
                        <div>
                              <label>Asset ID (Required):</label>
                              <input
                                    type="text"
                                    value={assetIdAsset}
                                    onChange={(e) => setAssetIdAsset(e.target.value)}
                                    required
                              />
                        </div>

                        <div>
                              <label>Asset Title:</label>
                              <input
                                    type="text"
                                    value={assetTitleAsset}
                                    onChange={(e) => setAssetTitleAsset(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Asset directoryPath:</label>
                              <input
                                    type="text"
                                    value={directoryPathAsset}
                                    onChange={(e) => setDirectoryPathAsset(e.target.value)}
                              />
                        </div>
                        <div>
                              <label>Asset course:</label>
                              <input
                                    type="text"
                                    value={courseIdAsset}
                                    onChange={(e) => setCourseIdAsset(e.target.value)}
                              />
                        </div>


                        <div className="form-btns">
                              <button type="submit" className="btn-primary">Update Asset</button>
                        </div>
                  </form>


            </div>
      </>)
}
export default AdminPanel