import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import { useLocation } from "react-router-dom";
import MainLayout from "../../template/MainLayout"

const AgentPublicProfile = () => {
      const location = useLocation();

      const [agentData, setAgentData] = useState({

            walletAddress: '',
            nickName: '',
            bio: '',
            profileImage: '',
            bannerImage: '',
            socialLinks: [],
            email: '',
            telegram: ''
      });

      const [errors, setErrors] = useState([]);

      // Fetch agent data from the API on load
      useEffect(() => {
            // Replace with the actual API call to get the public agent data
            const fetchAgentData = async () => {
                  try {
                        let str = location.pathname
                        console.log(location.pathname)
                        let code = str.substring(7, str.length)
                        console.log(' on try fetch code is: ' + code)
                        // Example fetch call to get publicagent data
                        const res = await fetch(apiUrl + 'agent/publicProfile/' + code);
                        const data = await res.json();
                        if (res.ok) {
                              setAgentData(data);
                              console.log(data)
                        } else {
                              setErrors([{ msg: 'Failed to load agent data' }]);
                        }
                  } catch (error) {
                        setErrors([{ msg: 'Error fetching agent data' }]);
                        console.log(error)
                  }
            };

            fetchAgentData();

      }, []);

      return (
            <MainLayout>
                  <div className="public-agent-profile">
                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {/* Banner Image */} 
                        <div className="banner-section" style={{ textAlign: 'center', position: 'relative' }}>
                              {agentData.bannerImage && (
                                    <img
                                          src={agentData.bannerImage}
                                          alt="Banner"
                                          style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                                    />
                              )}

                              {/* Profile Image */}
                              {agentData.profileImage && (
                                    <img
                                          src={agentData.profileImage}
                                          alt="Profile"
                                          style={{
                                                width: '150px',
                                                height: '150px',
                                                borderRadius: '50%',
                                                position: 'absolute',
                                                bottom: '-75px',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                border: '5px solid white'
                                          }}
                                    />
                              )}
                        </div>

                        {/* Nickname */}
                        <div style={{ textAlign: 'center', marginTop: '100px' }}>
                              <h1 style={{ fontSize: '48px', fontWeight: 'bold' }}>{agentData.nickName}</h1>
                        </div>

                        {/* Bio */}
                        {agentData.bio && (
                              <div className="info-display">
                                    <div className="bio-section " style={{ textAlign: 'center', marginTop: '20px' }}>
                                          <p>{agentData.bio}</p>
                                    </div>
                              </div>
                        )}

                        {/* Social Links */}
                        <div className="social-links-display" style={{ textAlign: 'center', marginTop: '30px' }}>
                              <h3>Check out my content on:</h3>
                              {
                                    console.log("agentData.socialLinks.length: " + agentData.socialLinks.length)
                              }
                              {agentData.socialLinks.length > 0 && (
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                          {agentData.socialLinks.map((link, index) => (
                                                <li key={index} style={{ margin: '10px 0' }}>
                                                      <a href={link.link} target="_blank" rel="noopener noreferrer">
                                                            {link.name}
                                                      </a>
                                                </li>
                                          ))}
                                    </ul>
                              )}
                              {/* 
                              {agentData.telegram && (
                                    <p>
                                          <strong>Telegram:</strong> <a href={`https://t.me/${agentData.telegram}`} target="_blank" rel="noopener noreferrer">{agentData.telegram}</a>
                                    </p>
                              )} */}
                        </div>
                  </div>
            </MainLayout>
      );
};

export default AgentPublicProfile;
