import React, { useState } from 'react';
import { apiUrl } from '../../../../asset/js/allStatics'
import MainLayout from "../../template/MainLayout"

const AgentRegister = () => {

      // Define form data and error state
      const [formData, setFormData] = useState({
            walletAddress: '',
            nickName: '',
            email: '',
            password: '',
            telegram: '',
            telegram: '',
            termsAgreed: true, // New state for the checkbox

      });

      const [errors, setErrors] = useState([]);
      const [successMessage, setSuccessMessage] = useState('');
      const [successComment, setSuccessComment] = useState({});
      const [showForm, setShowForm] = useState(true);

      const { walletAddress, nickName, email, password,
            telegram, termsAgreed } = formData;

      // Handle input changes
      const onChange = (e) => {
            setFormData({
                  ...formData, [e.target.name]: e.target.value
                  , [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            });

            if (e.target.type === 'checkbox') {
                  errors.termsAgreed = ''
            }
      };


      const handleInputChange = (e) => {
            // setFormData({
            //       ...formData,
            //       [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            // });
      };

      // Handle form submission
      const onSubmit = async (e) => {
            e.preventDefault();


            // Basic validation
            let errors = {};
            if (!formData.walletAddress) errors.walletAddress = 'Wallet address is required';
            if (!formData.nickName) errors.nickName = 'Nickname is required';
            if (!formData.email) errors.email = 'Email is required';
            if (!formData.password) errors.password = 'Password is required';
            if (!formData.termsAgreed) errors.termsAgreed = 'You must agree with terms and conditions';

            if (Object.keys(errors).length > 0) {
                  setErrors(errors);
                  return;
            }




            try {
                  let ss = apiUrl;

                  console.log('try to fetch: ' + ss + 'agent/register')
                  // Send POST request using fetch
                  formData.referralCode = Date.now()
                  const response = await fetch(ss + 'agent/register', {
                        method: 'POST',
                        headers: {
                              'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                  });

                  const resData = await response.json();

                  if (response.ok) {
                        // Successful response
                        setSuccessMessage(resData.msg);
                        setSuccessComment(
                              { show: true }
                        );
                        setShowForm(false)

                        setErrors([]); // Clear errors on success

                  } else {
                        // If there are validation errors
                        console.log(resData)
                        setErrors([{ msg: resData.msg }] || [{ msg: 'Server error' }]);
                  }

            } catch (err) {
                  // Handle general errors
                  setErrors([{ msg: 'Server error, please try again later.' }]);
            }
      };
      if (!formData.termsAgreed) errors.termsAgreed = 'You must agree with terms and conditions';


      return (
            <MainLayout >

                  <div className="form-container">
                        <h2>Register as an Agent</h2>
                        <br />
                        {errors.length > 0 && (
                              <div className="error-messages">
                                    {errors.map((error, index) => (
                                          <p key={index} style={{ color: 'red' }}>{error.msg}</p>
                                    ))}
                              </div>
                        )}

                        {successMessage && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>{successMessage}</p>
                              </div>
                        )}
                        {successComment.show && (
                              <div className="success-message" style={{ color: 'green' }}>
                                    <p>If you haven't receive the verification email in a minute, check your spam folder
                                          or <a href="/agent/resend/">Request Resend Email</a></p>
                              </div>
                        )}


                        <form onSubmit={onSubmit} style={{ display: (showForm ? 'block' : 'none') }}>
                              <div className="form-group">
                                    <label htmlFor="walletAddress">Wallet Address</label>
                                    <input
                                          type="text"
                                          name="walletAddress"
                                          value={walletAddress}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                    />
                              </div>

                              <div className="form-group">
                                    <label htmlFor="nickName">Nickname</label>
                                    <input
                                          type="text"
                                          name="nickName"
                                          value={nickName}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                    />
                                    {errors.nickName && <p style={{ color: 'red' }}>{errors.nickName}</p>}
                              </div>

                              <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                          type="email"
                                          name="email"
                                          value={email}
                                          onChange={onChange}
                                          required
                                          className="form-control"
                                    />
                                    {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                              </div>

                              <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                          type="password"
                                          name="password"
                                          value={password}
                                          onChange={onChange}
                                          minLength="6"
                                          required
                                          className="form-control"
                                          autoComplete="new-password"
                                    />
                                    {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
                              </div>

                              <div className="form-group">
                                    <label htmlFor="telegram">Telegram (optional)</label>
                                    <input
                                          type="telegram"
                                          name="telegram"
                                          value={telegram}
                                          onChange={onChange}
                                          minLength="6"
                                          className="form-control"
                                    />
                              </div>
                              {/* Terms and Conditions Checkbox */}



                              <div className="form-group">
                                    <label htmlFor="">
                                          <input
                                                type="checkbox"
                                                name="termsAgreed"
                                                checked={formData.termsAgreed}
                                                onChange={onChange}
                                          // onChange={handleInputChange}
                                          />  &nbsp;
                                          I agree with the <a href="/terms" target="_blank">Terms and Conditions</a>
                                    </label>
                                    {errors.termsAgreed && <p style={{ color: 'red' }}>{errors.termsAgreed}</p>}

                              </div>


                              <button type="submit" className="reg-btn">Register</button>
                              <div className='already'>
                                    Already registered? Go to <a href="./agent/login">login</a>
                              </div>
                              <br /><br />
                        </form>
                  </div>
            </MainLayout >

      );
};

export default AgentRegister;
